/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createCommand = /* GraphQL */ `
  mutation CreateCommand(
    $input: CreateCommandInput!
    $condition: ModelCommandConditionInput
  ) {
    createCommand(input: $input, condition: $condition) {
      id
      store_code
      state
      shoe_model
      shoe_size
      shoe_ec_url
      foot_model
      foot_length_right
      foot_width_right
      foot_heel_width_right
      foot_instep_height_right
      foot_length_left
      foot_width_left
      foot_heel_width_left
      foot_instep_height_left
      fitting_rate
      error_code
      message
      token
      user
      option
      createdAt
      updatedAt
    }
  }
`;
export const updateCommand = /* GraphQL */ `
  mutation UpdateCommand(
    $input: UpdateCommandInput!
    $condition: ModelCommandConditionInput
  ) {
    updateCommand(input: $input, condition: $condition) {
      id
      store_code
      state
      shoe_model
      shoe_size
      shoe_ec_url
      foot_model
      foot_length_right
      foot_width_right
      foot_heel_width_right
      foot_instep_height_right
      foot_length_left
      foot_width_left
      foot_heel_width_left
      foot_instep_height_left
      fitting_rate
      error_code
      message
      token
      user
      option
      createdAt
      updatedAt
    }
  }
`;
