import React, {useState, useEffect, useRef} from 'react';
import { useSelector, useDispatch, createDispatchHook } from 'react-redux';
import  * as action from '../actions/index';
import { TweenMax, TimelineMax } from "gsap"
import styled from 'styled-components';

import CharacterBear from './Character/CharacterBear'

export default function Done() {
  const dispatch = useDispatch()
  const [error, setError] = useState(null);
  const w = useSelector(state => state.window)

  const BearEl = useRef(null)

  // Note: the empty deps array [] means
  // this useEffect will run once
  // similar to componentDidMount()
  useEffect(() => {
    dispatch({type: "SET_DONE"})
  }, [])

  return (
    <Root>
      <BearWrap ref={BearEl}  w={w.wrate}><CharacterBear w={w.wrate} /></BearWrap>
        <Start>
        <ErrorTxt>足のサイズは、<br />LINEの「トーク」で確認してね。</ErrorTxt>
        <ErrorTxt> THANK YOU!</ErrorTxt>
        </Start>
    </Root>
  );
}

const Root = styled.div`
  position: relative;
  min-height: 100vh; /* 全画面表示 */
  max-height: -webkit-fill-available;
  max-height: -moz-available;
  max-height: stretch;
  margin: 0;
  padding: 0;
`

const ErrorTxt = styled.div`
  font-size: 1.8rem;
  font-weight: bold;
  color: #ffffff
`
const Start = styled.div`
position: fixed;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  width: 100%;
  text-align: center;
`

const BearWrap = styled.div`
position: fixed;
  bottom: ${({ w}) =>  (-100 * w) + "px"};
  left: 50%;
  transform: translateX(-50%) scale(1,1);
  -webkit-transform: translateX(-50%) scale(1,1);
`