
const error = {
  "401" : { state: true, msg:  "靴下が黒い(もしくは、柄に黒い部分がある)かボトムスの裾が上がっていない可能性があります。\\nガイドを確認しながら再計測してください。"},
  "402" : { state: true, msg: "撮影に失敗した能性があります。\\nガイドを確認しながら再計測してください。"}, 
  "403" : { state: true, msg: "靴下が黒い可能性があります。\\n靴下を脱いで再計測してください。"}, 
  "501" : { state: true, msg: "カメラの調整が必要です"},
  "502" : { state: true, msg: "計測エラー"},
  "503" : { state: true, msg: "通信状況が悪い可能性があります"}, // FDAPIエラー
  "504" : { state: true, msg: "通信状況が悪い可能性があります"}, // 外部APIエラー
  "505" : { state: false, msg: "GrapchQL系エラー"},
  "506" : { state: true, msg: "データ取得エラー"},
  "507" : { state: true, msg: "LINEのトークルームで\n６桁の数字を入力してください"}　// queryパラメータエラー
}

export function getErrorMsg(code) {
    return error[`${code}`]
}