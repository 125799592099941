import React, {useState, useEffect, useRef} from 'react';
import { Transition } from 'react-spring/renderprops'
import styled from 'styled-components'
import { TweenMax, TimelineMax } from "gsap"



import body from './assets/horse/horse_body.svg'
import left from './assets/horse/horse_ear_left.svg'
import right from './assets/horse/horse_ear_right.svg'


const tl = new TimelineMax({paused: false, repeat: -1});

export default function CharacterHorse(props) {
  const BodyEl = useRef(null)
  const LeftEl = useRef(null)
  const RightEl = useRef(null)


  useEffect(() => {
    // Update the document title using the browser API

    tl.fromTo(LeftEl.current, 1, 
      { rotation:  25, transformOrigin:"100% 0 0"},
      { rotation: '-=30', transformOrigin:"100% 0 0" }
    ).fromTo(RightEl.current, 1, 
      { rotation:  -25, transformOrigin:"0 0 0"},
      { rotation: '+=30', transformOrigin:"0 0 0" },
    )

  });



    return (
        <Charcter>
            <Left ref={LeftEl} src={left} width="80"/>
            <Right ref={RightEl} src={right} width="65"/>
            <Body ref={BodyEl} src={body} width="270" />
        </Charcter>
    );
  }

const Charcter = styled.div`
  position: relative;
  width: 400px;
  height: 400px;
`
const Body = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
`
const Left = styled.img`
  position: absolute;
  left: 15px;
  bottom: 50px;
`
const Right = styled.img`
  position: absolute;
  right: 170px;
  bottom: -20px;
`