import React, {useState, useEffect, useRef} from 'react';
import { useSelector, useDispatch, createDispatchHook } from 'react-redux';
import uuid from 'react-uuid';
//import {getItems , setScene, getFdcode, createAppSync,  pushGtm, updateTimer } from '../actions/index';
import  * as action from '../actions/index';
import { TweenMax, TimelineMax } from "gsap"
import styled from 'styled-components';
import { ButtonCloud } from './buttons/ButtonCloud' 
import BackButton from './buttons/ButtonsA' 
import bgBtn from '../assets/images/btn_bg.svg'
import next from '../assets/images/next_text.svg'
import cloudA from '../assets/images/cloudA.svg'
import cloudB from '../assets/images/cloudB.svg'
import backBtnImg from '../assets/images/back_btn.svg'



export default function Intro() {

  const [error, setError] = useState(null);
  const [isDisabled, setDisabled] = useState(false)
  const [isStartBtn, setStartBtn] = useState(true)
  const [digitizer, setStateDigitizer] = useState("");
  const [sessionUuid, setSessiontUuid] = useState("");

  const s = useSelector(state => state);
  const items = useSelector(state => state.items);
  const dispatch = useDispatch();
  const TopBtnEl = useRef(null)
  const CloudA1El = useRef(null)
  const CloudA2El = useRef(null)
  const CloudB1El = useRef(null)
  const CloudB2El = useRef(null)
  const CloudB3El = useRef(null)
  const CloudB4El = useRef(null)

  const tlBottom = new TimelineMax({paused: false, repeat: -1, repeatDelay: 5});
  const tlTop = new TimelineMax({paused: false, repeat: -1, repeatDelay: 5});

  // Note: the empty deps array [] means
  // this useEffect will run once
  // similar to componentDidMount()
  useEffect(() => {

    //TweenMax.from(TopBtnImageEl.current, 2, { repeatDelay: 5 , scale: 1.1, ease: "elastic.out(1, 0.2)", repeat: -1})
    TweenMax.to(TopBtnEl.current, 2, { y:10, yoyo: true, repeat: -1})
    TweenMax.to(CloudA1El.current, 2, { y:5,x: 5, yoyo: true, repeat: -1})
    TweenMax.to(CloudA2El.current, 2, { rotate:10, yoyo: true, repeat: -1})
    TweenMax.to(CloudB1El.current, 2, { y:5, x:5, rotate: 5,  yoyo: true, repeat: -1})
    TweenMax.to(CloudB1El.current, 2, { y:-10, yoyo: true, repeat: -1})
    TweenMax.to(CloudB2El.current, 2, { y:10, yoyo: true, repeat: -1})
    TweenMax.to(CloudB3El.current, 2, { y:10, yoyo: true, repeat: -1})
    TweenMax.to(CloudB4El.current, 2, { y:10, yoyo: true, repeat: -1})


  }, [])

  function hundleNextScene() {
    if ( !items[0]) {
      dispatch({type: "SET_ERROR", error: "通信状況を確認してください"})
      return 
    }  
    setDisabled(true)
    TweenMax.to(TopBtnEl.current, 1, { scale: 0, ease: "power2.inOut", onComplete : function(){
      action.createAppSync({ store_code: s.digitizer , state: "ACSELECTCHARACTER"})
      action.pushGtm(s.gtm, { state: "ACSELECTCHARACTER", operation: "NEXT", shoe_id: items[0].model} )
      dispatch({ type:"SET_SELECTED", selected:items[0].model })
      action.setScene("Characters", dispatch)
    }})
    action.updateTimer(s.timer, s.gtm, dispatch)
    action.sendSlackMessage(digitizer,sessionUuid)
  }

  const hundleChangeScene = (scene) => () => {  
    try {
      setDisabled(true)
      let input = { state: "STANDBY", store_code: s.digitizer}

      action.setScene("Top",  dispatch)
      action.createAppSync(input)

      setDisabled(false)
      action.pushGtm(s.gtm, { state: "STANDBY", operation: "BACK"} )
      action.updateTimer(s.timer, s.gtm, dispatch)
    } catch(e){
      dispatch({type: "SET_ERROR", error:e.message})
    }
  }


  return (
    <Root>
        <Start>
        <ButtonCloud ref={TopBtnEl} isabled={isDisabled}  hundleNext={hundleNextScene} src={bgBtn} imgwidth={500}>
          <img src={next} width="584" />
        </ButtonCloud>
        </Start>
        <CloudA1 ref={CloudA1El} src={cloudA} />
        <CloudA2 ref={CloudA2El} src={cloudA} />
        <CloudB1 ref={CloudB1El} src={cloudB} />
        <CloudB2 ref={CloudB2El} src={cloudB} />
        <CloudB3 ref={CloudB3El} src={cloudB} />
        <CloudB4 ref={CloudB4El} src={cloudB} />
        <TopButton 
        isDisabled={isDisabled}
        onTouchStart={hundleChangeScene}  scene={"Top"} >
          <img src={backBtnImg} width={200} />
        </TopButton>

    </Root>
  );
}

const Root = styled.div`
  position: relative;
  height: 100vh; /* 全画面表示 */
  margin: 0;
  padding: 0;
`
const Start = styled.div`
position: fixed;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  width: 100%;
  text-align: center;
`

const  CloudA1 = styled.img`
  position: fixed;
  top: 50px;
  left: 160px;
  width: 204px;
`
const  CloudA2 = styled.img`
  position: fixed;
  bottom: 145px;
  right: 200px;
  width: 204px;
`

const  CloudB1 = styled.img`
  position: fixed;
  top: 265px;
  left: -40px;
  width: 180px;
`
const  CloudB2 = styled.img`
  position: fixed;
  top: 160px;
  right: 45px;
  width: 180px;
  transform: rotate(-30deg);
  -webkit-transform: rotate(-30deg);
`
const  CloudB3 = styled.img`
  position: fixed;
  bottom: 80px;
  left: 30px;
  width: 180px;
  transform: rotate(-30deg);
  -webkit-transform: rotate(-30deg);
`
const  CloudB4 = styled.img`
  position: fixed;
  bottom: 50px;
  right: -30px;
  width: 180px;
`

const TopButton = styled(BackButton)`
  position: absolute;
  top: 40px;
  left: 35px;
`