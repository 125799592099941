import * as React from 'react'
import { useDispatch } from 'react-redux';
import styled from 'styled-components'

export default function ErrorDialog(props) {
  const dispatch = useDispatch();

  const hundleCancel = () => {
    dispatch({type: "SET_ERROR", error: null})
  }
  

  return (
    <Root>
      <Wrapper>
        <dl>
          <dt>
            <i className="fas fa-exclamation-triangle" />
            !
          </dt>
          <dd>
            <p>{props.error}</p>
            <LineMsg>LINEの番号入力からやり直します</LineMsg>
            <p>
              <ButtonCancel onTouchStart={props.hundleCancel? props.hundleCancel: hundleCancel}>キャンセル</ButtonCancel>
              <Button onTouchStart={() => window.location.reload()}>OK</Button>
            </p>
          </dd>
        </dl>
      </Wrapper>
    </Root>
  );
}

  const Root = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 99999;
`

const Wrapper = styled.div`
  max-width: 90%;
  background-color: #fff;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 20px;
  dl {
    text-align: center;
  }
  dt {
    color: #cc0000;
    font-size: 20px;
    i {
      margin-right: 10px;
    }
  }
  p {
    margin: 20px 0;
  }
`

const LineMsg = styled.p`
  margin: 5px 0;
  font-size: 12px;
  color: ${({ theme }) => theme.color.grey5 };
`



const Button = styled.button`
  height: 50px;
  min-width: 100px;
  max-width: 100px;
  font-weight: bold;
  border-radius: 25px;
  margin: 0 15px;
  color: ${({ theme }) => theme.color.white };
  background-color: ${({ theme }) => theme.color.key };
`

const ButtonCancel = styled.button`
  height: 50px;
  min-width: 100px;
  max-width: 100px;
  font-weight: bold;
  border-radius: 25px;
  margin: 0 15px;
  color: ${({ theme }) => theme.color.white };
  background-color: ${({ theme }) => theme.color.grey5 };
`

