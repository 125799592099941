import React, {useState, useEffect, useRef} from 'react';
import { Transition } from 'react-spring/renderprops'
import styled from 'styled-components'
import { TweenMax, TimelineMax } from "gsap"



import body from './assets/mouse/mouse_body.svg'
import left from './assets/mouse/mouse_ whisker_left.svg'
import right from './assets/mouse/mouse_ whisker_right.svg'


const tl = new TimelineMax({paused: false, repeat: -1});

export default function CharacterMouse(props) {
  const BodyEl = useRef(null)
  const LeftEl = useRef(null)
  const RightEl = useRef(null)


  useEffect(() => {
    // Update the document title using the browser API


    tl.fromTo(RightEl.current, 1, 
      { scale:  0.6,  ease:"bounce.out"},
      { scale: 1.0, ease:"bounce.out"}
    ).fromTo(RightEl.current, 1, 
      { scale: 1.0, ease:"bounce.out"},
      { scale:  0.6,  ease:"bounce.out"}
    ).fromTo(LeftEl.current, 1, 
      { scale:  0.6,  ease:"bounce.out"},
      { scale: 1.0, ease:"bounce.out"},
      "-=2"
    ).fromTo(LeftEl.current, 1, 
      { scale: 1.0, ease:"bounce.out"},
      { scale:  0.6,  ease:"bounce.out"},
      "-=1"
    )


    });






    return (
        <Charcter>
            <Body ref={BodyEl} src={body} width="300" />
            <Left ref={LeftEl} src={left} width="50"/>
            <Right ref={RightEl} src={right} width="50"/>
        </Charcter>
    );
  }

const Charcter = styled.div`
  position: relative;
  width: 300px;
  height: 300px;
`
const Body = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
`
const Right = styled.img`
  position: absolute;
  top: 40%;
  right: 50px;
`
const Left = styled.img`
  position: absolute;
  top: 40%;
  left: 50px;
`