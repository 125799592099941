import React, {useState, useEffect, useRef} from 'react';
import { useSelector, useDispatch, createDispatchHook } from 'react-redux';
//import {getItems , setScene, getFdcode, createAppSync,  pushGtm, updateTimer } from '../actions/index';
import  * as action from '../actions/index';
import { TweenMax, TimelineMax } from "gsap"
import styled from 'styled-components';
import { ButtonCloud } from './buttons/ButtonCloud' 
import BackButton from './buttons/ButtonsA' 
import backBtnImg from '../assets/images/back_btn.svg'
import bgBtn from '../assets/images/btn_bg.svg'
import next from '../assets/images/measurement_text.svg'
import cloudA from '../assets/images/cloudA.svg'
import cloudB from '../assets/images/cloudB.svg'

export default function Guide() {

  const [error, setError] = useState(null);
  const [isDisabled, setDisabled] = useState(false)
  const [isDialog, setDialog] = useState(false)

  const w = useSelector(state => state.window);
  const dispatch = useDispatch();
  const selectedModel = useSelector(state => state.selected);
  const digitizer = useSelector(state => state.digitizer);
  const token = useSelector(state => state.token);
  const gtm = useSelector(state => state.gtm);
  const timer = useSelector(state => state.timer)

  const StartEl = useRef(null)
  const TextEl = useRef(null)
  const NextBtnEl = useRef(null)
  const CloudA1El = useRef(null)
  const CloudA2El = useRef(null)
  const CloudB1El = useRef(null)
  const CloudB2El = useRef(null)
  const CloudB3El = useRef(null)
  const CloudB4El = useRef(null)

  // Note: the empty deps array [] means
  // this useEffect will run once
  // similar to componentDidMount()
  useEffect(() => {

    setTimeout(() => { 
      TweenMax.to(TextEl.current, 0.5,{ scale: 0, delay: 0, ease: "power2.inOut"
      })

      TweenMax.to(StartEl.current, 0.5,{ scale: 1, delay: 1, ease: "power2.inOut",
        onComplete : function(){
          TweenMax.to(NextBtnEl.current, 2, { y:10, yoyo: true, repeat: -1})
        }
      })
    } , 18000 )

    const f = async () => {
      try {
        await action.tokenCheck(token)
      } catch (e) {
      }
    }
    f()

    /*
    TweenMax.to(CloudA1El.current, 2, { y:5,x: 5, yoyo: true, repeat: -1})
    TweenMax.to(CloudA2El.current, 2, { rotate:10, yoyo: true, repeat: -1})
    TweenMax.to(CloudB1El.current, 2, { y:5, x:5, rotate: 5,  yoyo: true, repeat: -1})
    TweenMax.to(CloudB1El.current, 2, { y:-10, yoyo: true, repeat: -1})
    TweenMax.to(CloudB2El.current, 2, { y:10, yoyo: true, repeat: -1})
    TweenMax.to(CloudB3El.current, 2, { y:10, yoyo: true, repeat: -1})
    TweenMax.to(CloudB4El.current, 2, { y:10, yoyo: true, repeat: -1})
    */

  }, [])

  async function  hundleNextScene(){
    let gtm_operation = "NEXT"
    setDisabled(true)

    try {
      // 計測前いにFootUuidを取得する
      const footUuid =  await action.getFootUuid(digitizer, dispatch)
      dispatch({type: "SET_FOOTUUID", footuuid:footUuid})



      let input = { 
        state: "ACMEASURING", 
        store_code: digitizer,
        token: token,
        option: footUuid,
        shoe_model: selectedModel}
      TweenMax.to(NextBtnEl.current, 0.5, { scale: 0, ease: "power2.inOut",
      onComplete : function(){
        action.updateTimer(timer, gtm, dispatch)
        action.createAppSync(input)
        action.setScene("Mesurement", dispatch)
        action.pushGtm(gtm, { state: input.state, operation: gtm_operation, shoe_id :  input.shoe_model ? input.shoe_model : null } )
        setDisabled(false)
      }
    })

    } catch (e) {
      dispatch({type: "SET_ERROR", error:e.message})
    }
  }

  const  hundleBack = (scene) => () =>  {
    let gtm_operation = "RESET"
    setDisabled(true)
    try {
      let input = { 
        state: "STANDBY", 
        store_code: digitizer,
        token: token,
      }

      action.updateTimer(timer, gtm, dispatch)
      action.createAppSync(input)
      action.setScene("Done", dispatch)
      action.pushGtm(gtm, { state: input.state, operation: gtm_operation, shoe_id :  input.shoe_model ? input.shoe_model : null } )
      setDisabled(false)

    } catch (e) {
      dispatch({type: "SET_ERROR", error:e.message})
    }
  }

  const hundleDialog = () => () => {
    setDialog(true)
  }

  return (
    <Root>
        <Start ref={StartEl}>
          <ButtonCloud ref={NextBtnEl} isabled={isDisabled}  hundleNext={hundleNextScene}   src={bgBtn} imgwidth={400*w.wrate}>
          <img src={next} width="584" /> 
          </ButtonCloud>
        </Start>
        <Text ref={TextEl}>
          注意事項を確認してね
        </Text>

        {/* 
        <CloudA1 ref={CloudA1El} src={cloudA} />
        <CloudA2 ref={CloudA2El} src={cloudA} />
        <CloudB1 ref={CloudB1El} src={cloudB} />
        <CloudB2 ref={CloudB2El} src={cloudB} />
        <CloudB3 ref={CloudB3El} src={cloudB} />
        <CloudB4 ref={CloudB4El} src={cloudB} />
        */}

        <TopButton 
        isDisabled={isDisabled}
        onTouchStart={hundleDialog}    scene={"Top"} >
            キャンセル
        </TopButton>

        {  isDialog && 
      <DialogRoot>
      <DialogWrapper>
        <dl>
          <dt>
            <i className="fas fa-exclamation-triangle" />
            <p>LINEで番号入力からやり直しになります</p>
            <p>キャンセルしてよろしいですか？？</p>
          </dt>
          <dd>
            <p>
              <DialogButton2 onTouchStart={() => setDialog(false)}>しない</DialogButton2>
              <DialogButton onTouchStart={() => window.location.reload(true)}>する</DialogButton>
            </p>
          </dd>
        </dl>
      </DialogWrapper>
      </DialogRoot>
    }

    </Root>
  );
}

const Root = styled.div`
  position: relative;
  height: 100vh; /* 全画面表示 */
  margin: 0;
  padding: 0;
`
const Button = styled.button`
  position: relative;
  text-align: center;
`
const Btnimg = styled.img`
`
const Btntxt = styled.span`
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  font-size: 4.0rem;
  font-weight: bold;
  color: #767676;

`


const Start = styled.div`
position: fixed;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%) scale(0,0);
  -webkit-transform: translateX(-50%) translateY(-50%) scale(0,0);
  width: 100%;
  text-align: center;
`

const Text = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  width: 100%;
  text-align: center;
  font-size: 2.0rem;
  font-weight: bold;
  color: #fff;
`



const TopButton = styled(BackButton)`
  position: absolute;
  top: 20px;
  left: 10px;
`


const  CloudA1 = styled.img`
  position: fixed;
  top: 50px;
  left: 160px;
  width: 204px;
`
const  CloudA2 = styled.img`
  position: fixed;
  bottom: 145px;
  right: 200px;
  width: 204px;
`

const  CloudB1 = styled.img`
  position: fixed;
  top: 265px;
  left: -40px;
  width: 180px;
`
const  CloudB2 = styled.img`
  position: fixed;
  top: 160px;
  right: 45px;
  width: 180px;
  transform: rotate(-30deg);
  -webkit-transform: rotate(-30deg);
`
const  CloudB3 = styled.img`
  position: fixed;
  bottom: 80px;
  left: 30px;
  width: 180px;
  transform: rotate(-30deg);
  -webkit-transform: rotate(-30deg);
`
const  CloudB4 = styled.img`
  position: fixed;
  bottom: 50px;
  right: -30px;
  width: 180px;
`

const DialogRoot = styled.div`
position: fixed;
top: 0;
left: 0;
width: 100vw;
height: 100vh;
display: flex;
justify-content: center;
align-items: center;
background-color: rgba(0, 0, 0, 0.7);
z-index: 99999;
`

const DialogWrapper = styled.div`
width: 80%;
background-color: #fff;
border-radius: 15px;
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
padding: 10px;
font-size: 1.0rem;
dl {
  text-align: center;
}
dt {
  color: #cc0000;
  font-size: 14px;
  i {
    margin-right: 10px;
  }
}
p {
  margin: 20px 0;
}
`

const DialogButton = styled.button`
height: 50px;
min-width: 100px;
font-weight: bold;
  font-size: 14px;
border-radius: 25px;
margin: 0 10px;
color: ${({ theme }) => theme.color.white };
background-color: #F4C220;
`

const DialogButton2 = styled.button`
height: 50px;
min-width: 100px;
font-weight: bold;
  font-size: 14px;
border-radius: 25px;
margin: 0 10px;
color: ${({ theme }) => theme.color.white };
background-color: ${({ theme }) => theme.color.grey2 };
`