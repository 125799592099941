import React, {Fragment, useState, useCallback, useEffect, useRef } from 'react'
import { useSelector, useDispatch, Provider } from "react-redux"
import { useBeforeunload } from 'react-beforeunload';
import { ThemeProvider } from 'styled-components'
import * as Sentry from "@sentry/react"
import { Integrations } from "@sentry/tracing"
import GlobalStyle from './components/GlobalStyle'
import theme from './theme'
import './App.css'
import store from './store'
import {ClearTimer,createAppSync } from './actions'

import  cron from 'node-cron'

import Top from './components/Top'
import Intro from './components/Intro'
import CharacterGuide from './components/CharacterGuide';
import Mesurement from './components/Mesurement';
import Result from './components/Result';
import Tryon from './components/Tryon';
import Characters from './components/Characters';
import Guide from './components/Guide';
import ErrorDialog from './components/ErrorDialog';
import Done from './components/Done';


Sentry.init({
  dsn: "https://c4f52bc2cdb44f5eb92b77aecbc5185a@o470372.ingest.sentry.io/5500885",
  integrations: [
    new Integrations.BrowserTracing(),
  ],

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
});


// リロード
cron.schedule('0 0 1 * * *', () => {
  window.location.reload(true)
});

function Router ()   {
  const scene = useSelector(state => state.scene);
  const error = useSelector(state => state.error);
  const errorHundleCancel = useSelector(state => state.errorHundleCancel);
  const timer = useSelector(state => state.timer);
  const digitizer = useSelector(state => state.digitizer);
  const token = useSelector(state => state.token);
  const dispatch = useDispatch()
  const prevdigitizer = usePrevious(digitizer);
  const prevScene = usePrevious(scene);

  function usePrevious(value) {
    const ref = useRef(null);
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }

  useEffect(() => {

    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);


    if(scene === "Top") {
      ClearTimer(timer, dispatch)
    }
    console.log('SCENE', scene)
    dispatch({type: "SET_WINDOW", window : {height: window.innerHeight, width: window.innerWidth} })


   // イベント（リスナ）の登録
   //window.addEventListener('visibilitychange', handlePageHide);


   /*
    window.addEventListener("pagehide",async function(e){
      document.cookie="event1="+e.type;
      document.cookie="digitizer="+digitizer;
      if ( digitizer && token){
        let input = { 
            state: "STANDBY_DDD", 
            store_code: digitizer,
            token: token
        }
        await createAppSync(input)
        document.cookie="event=2222";
      }
    });
    */


 
    return () => {
      // Unmount時の処理を記述
      //window.removeEventListener('beforeunload', beforeunload)
      //window.removeEventListener('visibilitychange', handlePageHide);
    };
  }, [scene])

  // mobileSafariではunberoreunloadイベントが取れないため利用しない
  /*
  useBeforeunload((event) => {
    // 閉じる処理
    if ( digitizer && token){
      let input = { 
          state: "STANDBY_CCC", 
          store_code: digitizer,
          token: token
      }
      createAppSync(input)
    }
    // 指定ミリ秒間だけループさせる（CPUは常にビジー状態）
    var startMsec = new Date();
    while (new Date() - startMsec < 20000);
  });
  */
 

  const  handlePageHide = async () => {
    
    if(document.visibilityState == "visible" ) return
    // 閉じる(リロードも含む)処理
    
    // 初回表示のフィルタリング
    if (  digitizer && token ){
        let input = { 
            state: "STANDBY_DDD", 
            store_code: digitizer,
            token: token
        }
        await createAppSync(input)
    // 指定ミリ秒間だけループさせる（CPUは常にビジー状態）
    var startMsec = new Date();
    while (new Date() - startMsec < 8000);
    }
      
  }

  return (
    <Fragment>
      {scene === "Top" &&  <Top /> }
      {scene === "Done" &&  <Done /> }
      {scene === "CharacterGuide" &&  <CharacterGuide /> }
      {scene === "Characters" &&  <Characters /> }
      {scene === "Guide" &&  <Guide /> }
      {scene === "Mesurement" &&  <Mesurement /> }
      {scene === "Result" &&  <Result /> }
      { error !== null &&  <ErrorDialog  error={error} hundleCancel={errorHundleCancel} /> }
    </Fragment>


  )
}

function App () {
  return (
    <ThemeProvider theme={theme}>
      <Fragment>
        <GlobalStyle />
        <Provider store={store}>
          <Router />
        </Provider>
      </Fragment>
    </ThemeProvider>
  );
}

export default App;
