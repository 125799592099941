import React, {useState, useEffect, useRef} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import API, { graphqlOperation } from '@aws-amplify/api';
import config from '../config'; 
//import {setScene, updateAppSync, createAppSync, pushGtm, updateTimer } from '../actions/index'
import * as action from '../actions/index'
import { formatSize} from '../helpers/number'
import { onCreateCommand } from '../graphql/subscriptions'
import { TweenMax } from "gsap"

import BackButton from './buttons/ButtonsA'   
import Button from './buttons/ButtonsB' 
import { ButtonCloud } from './buttons/ButtonCloud'
import styled from 'styled-components'

import bgBtn from '../assets/images/btn_bg.svg'
import cloudA from '../assets/images/cloudA.svg'
import cloudB from '../assets/images/cloudB.svg'
import backBtnImg from '../assets/images/back_btn.svg'
import bearImg from '../assets/images/result_bear.svg'
import catImg from '../assets/images/result_cat.svg'
import reloadText from '../assets/images/reload_text.svg'
import { WindowsBalloon } from 'node-notifier';

export default function Result() {

  const [isLoaded, setIsLoaded] = useState(false);
  const [isDisabled, setDisabled] = useState(false) 
  const [isDialog, setDialog] = useState(false) 
  const [isNextDisabled, setNextDisabled] = useState(true) 
  const [isRestart, setRestart] = useState(false) 
  const [isRecoomenDisabled, setRecomenDisabled] = useState(true) 

  const s = useSelector(state => state);
  const w = useSelector(state => state.window);
  const size = useSelector(state => state.jissize);
  const ec = useSelector(state => state.ec);
  const recommend = useSelector(state => state.recommend);
  const commandid = useSelector(state => state.commandid);
  const digitizer = useSelector(state => state.digitizer);
  const token = useSelector(state => state.token);
  const foot_uuid = useSelector(state => state.footuuid);
  const gtm = useSelector(state => state.gtm);
  const timer = useSelector(state => state.timer);
  const retry = useSelector(state => state.retry);
  const selectedModel = useSelector(state => state.selected)
  const character = useSelector(state => state.character)

  const dispatch = useDispatch();
  const NextBtnEl = useRef(null)
  const RestartBtnEl = useRef(null)
  const CloudA1El = useRef(null)
  const CloudA2El = useRef(null)
  const CloudB1El = useRef(null)
  const CloudB2El = useRef(null)
  const BackBtnEl = useRef(null)

  useEffect(() => {

    TweenMax.to(CloudA1El.current, 2, { y:5,x: 5, yoyo: true, repeat: -1})
    TweenMax.to(CloudA2El.current, 2, { rotate:10, yoyo: true, repeat: -1})
    TweenMax.to(CloudB1El.current, 2, { y:5, x:5, rotate: 5,  yoyo: true, repeat: -1})
    TweenMax.to(CloudB1El.current, 2, { y:-10, yoyo: true, repeat: -1})
    TweenMax.to(CloudB2El.current, 2, { y:10, yoyo: true, repeat: -1})
 
    // タイマーをOFFにして、結果表示用のタイマー（5分）をセットする
    //action.resultTimer(timer, gtm,dispatch)
    const subscription = API.graphql(graphqlOperation(onCreateCommand, {store_code: digitizer})).subscribe({
      next: (eventData) => {
        if ( eventData.value.data.onCreateCommand.state == "ACPAYMENTSUCCESS" ) {
          // TOPボタンを表示
          setNextDisabled(true); 
          setRestart(true)
          TweenMax.to(RestartBtnEl.current, 0.5,{ scale: 1, delay: 0, ease: "power2.inOut"})
        } else if ( eventData.value.data.onCreateCommand.state == "ACPAYMENTFAIL" ) {
          // QR再表示
          //action.createAppSync({ store_code: digitizer, state: "RESULT" })
        }
      }
    })


    if ( retry === null  ) {
      let input = { 
          store_code: digitizer,
          token: token,
          state: "ACRESULT", 
          shoe_model: s.selected,
          shoe_size: recommend.size == "no_matches" ? `${recommend.size}` : `${recommend.size}cm`,
          message: "measurement_result",
          shoe_ec_url: `${ec}`,
          foot_model: s.file,
          foot_length_right: `${formatSize(size.right.length)}cm`,
          foot_width_right: size.right.jisWidthJa,
          foot_heel_width_right: size.right.heelWidthJa,
          foot_instep_height_right: size.right.instepHeightJa,
          foot_length_left: `${formatSize(size.left.length)}cm`,
          foot_width_left: size.left.jisWidthJa, 
          foot_heel_width_left: size.left.heelWidthJa,
          foot_instep_height_left: size.left.instepHeightJa,
          fitting_rate: recommend.fittingRate,
          option: foot_uuid
      } 

      if ( recommend.size == "no_matches" ) {
        //action.resultTimerShort(timer, gtm,dispatch)
      }
    
      try {
        action.createAppSync(input)
        setTimeout(() => { 
          // おすすめ表示ボタンの制御
          //setNextDisabled(false); 
          //TweenMax.to(NextBtnEl.current, 0.5,{ scale: 1, delay: 0, ease: "power2.inOut"})
          hundleRecommend()

        } , 15000 )

      } catch (e) {
        dispatch({type: "SET_ERROR", error:e.message})
      }
      action.pushGtm(s.gtm, { 
          state: input.state, 
          operation: "", 
          foot_uuid:foot_uuid, 
          fit: input.fitting_rate , 
          recommended_size: input.shoe_size, 
          shoe_id : input.shoe_model
      })
    } else {
      // 再計測
      //hundleChangeScene('Guide')
      action.resultTimerShort(timer, gtm,dispatch)
    }
    return () => subscription.unsubscribe()
  }, [])


  function updateGraphql (state) {
    let gtm_operation = "BUY"
    let input = { state: state, store_code: digitizer, token: token, option:foot_uuid , shoe_model: s.selected}

    if(input.state == "STANDBY") {
      gtm_operation = "RESET"
      delete input.shoe_model
    } else if(input.state == "ACGUIDE") {
      gtm_operation = "RETRY"
    }

    try { 
      action.createAppSync(input)
    } catch(e){
      dispatch({type: "SET_ERROR", error:e.message})
    }
    action.pushGtm(gtm, { state: input.state, operation: gtm_operation } )
    action.updateTimer(timer, gtm, dispatch)
  }

  const hundleChangeScene = (scene) => () => {
    setDisabled(true)
    let state =  "QR"

    if(scene == "Top" || scene == "Done") { 
      state = "STANDBY"
    } 

    if(scene == "Guide") { 
      dispatch({type: "SET_RETRY", retry:null})
      state = "ACGUIDE"
    }
    updateGraphql(state)
    action.setScene(scene, dispatch)
    setDisabled(false)
  } 

  // 大画面にレコメンドサイズを表示する
  const hundleRecommend = () => {

    setDisabled(true)

    let input = { 
      store_code: digitizer,
      token: token,
      state: "ACTHANKS", 
      shoe_model: s.selected,
      shoe_size: recommend.size == "no_matches" ? `${recommend.size}` : `${recommend.size}cm`,
      message: "recommend_no_qr",
      shoe_ec_url: `${ec}`,
      foot_model: s.file,
      foot_length_right: `${formatSize(size.right.length)}cm`,
      foot_width_right: size.right.jisWidthJa,
      foot_heel_width_right: size.right.heelWidthJa,
      foot_instep_height_right: size.right.instepHeightJa,
      foot_length_left: `${formatSize(size.left.length)}cm`,
      foot_width_left: size.left.jisWidthJa, 
      foot_heel_width_left: size.left.heelWidthJa,
      foot_instep_height_left: size.left.instepHeightJa,
      fitting_rate: recommend.fittingRate,
      option: foot_uuid
  }

    try {
      // ACTHANKSの送信を停止する
      //action.createAppSync(input)
      setNextDisabled(true); 
      //TweenMax.to(NextBtnEl.current, 0.5,{ scale: 1, delay: 0, ease: "power2.inOut"})
      setTimeout(() => { 
        // 終了ボタンを表示する
        setRecomenDisabled(false)
      } , 2000 ) 
      //  完了へ
      setTimeout(() => { 
        console.log("=======")
        hundleChangeScene('Done')()
      } , 30000 ) 


    } catch (e) {
      dispatch({type: "SET_ERROR", error:e.message})
    }
    setDisabled(false)
  } 

  const hundleReload = () => () => {
    action.resultTimer(timer, gtm ,dispatch)
    action.createAppSync({ store_code: digitizer, state: "ACRESULT" })
  } 

  const hundleDialog = () => () => {
    setDialog(true)
  }


  return (
    <Root>

      { retry != null && 
      <div>
        <Measuring retry={retry}>
          <ButtonCloud ref={NextBtnEl} isabled={isDisabled}  hundleNext={hundleChangeScene('Guide')}  src={bgBtn} imgwidth={500*w.wrate}>
            再計測
          </ButtonCloud>
        </Measuring>
        <TopButton onTouchStart={() => hundleDialog()}  scene={"Top"} >
          キャンセル
        </TopButton>
      </div>
      }


      { !isNextDisabled && 
      <Measuring retry={retry}>
          <ButtonCloud ref={NextBtnEl} isabled={isDisabled}  hundleNext={hundleRecommend()}  src={bgBtn} imgwidth={500*w.wrate}>
            おすすめ<br />サイズを表示
          </ButtonCloud>
      </Measuring>
      }

      { !isRecoomenDisabled && 
      <div>

        <Measuring retry={retry}>
            <ButtonCloud ref={RestartBtnEl} isabled={isDisabled}  hundleNext={hundleChangeScene('Done')}  src={bgBtn} imgwidth={500*w.wrate}>
              終了
            </ButtonCloud>
        </Measuring>
        <TopButton onTouchStart={() => hundleChangeScene('Done')}  scene={"Top"} >
          キャンセル
        </TopButton>
      </div>
      }



    { character == "bear" && 
      <Character bottom="-190px" wrate={w.wrate}><img src={bearImg} /></Character>
    }

    { character == "cat" && 
      <Character bottom="-240px" wrate={w.wrate}><img src={catImg} /></Character>
    }

    {  isDialog && 
      <DialogRoot>
      <DialogWrapper>
        <dl>
          <dt>
            <i className="fas fa-exclamation-triangle" />
            <p>LINEの番号入力からやり直しになります？？</p>
            <p>終了してよろしいですか？？</p>
          </dt>
          <dd>
            <p>
              <DialogButton2 onTouchStart={() => setDialog(false)}>キャンセル</DialogButton2>
              <DialogButton onTouchStart={()=>window.location.reload(true)}>TOPヘ</DialogButton>
            </p>
          </dd>
        </dl>
      </DialogWrapper>
      </DialogRoot>
    }




    </Root>
  );
}
const Root = styled.div`
  position: relative;
  min-height: 100vh; /* 全画面表示 */
  max-height: -webkit-fill-available;
  max-height: -moz-available;
  max-height: stretch;
  width: 100vw; /* 全画面表示 */
`

const Character = styled.div`
  width: ${({ wrate }) => wrate ? (600 * wrate) + "px": "600px" };;
  position: absolute;
  left: 50%;
  bottom: ${({ theme, bottom }) => bottom};
  transform: translateX(-50%);
  -webkit- transform: translateX(-50%);
`

const Measuring = styled.div`
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  -webkit- transform: translateY(-50%) translateX(-50%) ;
  width: ${({ theme, retry }) => (retry !== null)? "60%": "90%"};
  text-align: center;
`
const Inner = styled.div`
  position: relative;
`
const InfoText = styled.p`
  width: 100%;

`
const BuyButton = styled.button`
  padding: 0;
  margin-top: 100px;
  font-size: 3.0em;
`

const Text = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  -webkit- transform: translateX(-50%);
  width: 700px;
  color: ${({ theme }) => theme.color.grey2};
  font-size: 1.5em;
  text-align: center;
  bottom: 50px;
`
const MainText = styled.p`
  font-weight: bold;
  font-size: 6.0em;
  color: ${({ theme }) => theme.color.grey2};
`
const SubText = styled.p`
  color: ${({ theme }) => theme.color.key};
  font-size: 2.0em;
`

const NoMatches = styled.div`
position: absolute;
top: 50%;
left: 50%;
transform: translateY(-50%) translateX(-50%);
-webkit- transform: translateY(-50%) translateX(-50%);
width: 100%;
text-align: center
`


const TextNoMatches = styled.div`
  width: 100%;
  color: ${({ theme }) => theme.color.grey2};
  font-size: 3.0em;
  font-weight: bold;
  text-align: center;
`

const TopButtonNoMatches = styled(BackButton)`
  margin-top: 80px;
  width: 90%;
  font-size: 1.2em;
  border: 2px solid ${({ theme }) => theme.color.grey2};
`

const TopButton = styled(BackButton)`
  position: absolute;
  top: 20px;
  left: 10px;

`

const DialogRoot = styled.div`
position: fixed;
top: 0;
left: 0;
width: 100vw;
height: 100vh;
display: flex;
justify-content: center;
align-items: center;
background-color: rgba(0, 0, 0, 0.7);
z-index: 99999;
`

const DialogWrapper = styled.div`
width: 80%;
background-color: #fff;
border-radius: 15px;
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
padding: 10px;
font-size: 1.0rem;
dl {
  text-align: center;
}
dt {
  color: #cc0000;
  font-size: 14px;
  i {
    margin-right: 10px;
  }
}
p {
  margin: 20px 0;
}
`

const DialogButton = styled.button`
height: 50px;
min-width: 100px;
font-weight: bold;
  font-size: 14px;
border-radius: 25px;
margin: 0 10px;
color: ${({ theme }) => theme.color.white };
background-color: #F4C220;
`

const DialogButton2 = styled.button`
height: 50px;
min-width: 100px;
font-weight: bold;
  font-size: 14px;
border-radius: 25px;
margin: 0 10px;
color: ${({ theme }) => theme.color.white };
background-color: ${({ theme }) => theme.color.grey2 };
`








const Start = styled.div`
position: fixed;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  width: 100%;
  text-align: center;
`

const  CloudA1 = styled.img`
  position: fixed;
  top: 50px;
  left: 160px;
  width: 204px;
`
const  CloudA2 = styled.img`
  position: fixed;
  bottom: 145px;
  right: 200px;
  width: 204px;
`

const  CloudB1 = styled.img`
  position: fixed;
  top: 265px;
  left: -40px;
  width: 180px;
`
const  CloudB2 = styled.img`
  position: fixed;
  top: 160px;
  right: 45px;
  width: 180px;
  transform: rotate(-30deg);
  -webkit-transform: rotate(-30deg);
`