import React, {useState, useEffect, useRef} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { createCommand, updateCommand } from '../graphql/mutations'
//import {setScene, updateAppSync, createAppSync , pushGtm, updateTimer} from '../actions/index'
import * as action from '../actions/index'
import Button from './buttons/ButtonsA'   
import ItemButton from './buttons/itemDetailButton'   
import SceneButton from './buttons/ButtonsD'
import Thumbnail from './item/ItemThumbnail'   
import styled from 'styled-components'
import { TweenMax } from "gsap"
import backBtnImg from '../assets/images/back_btn.svg'
import measBtnTextImg from '../assets/images/btn_measurement_text.svg'


export default function Tryon() {
  const [isDisplay, setDisplay] = useState(false) 
  const [isDisabled, setDisabled] = useState(false) 
  const [isMeasBtnDisabled, setMeasBtnDisabled] = useState(true) 
  const [selected, setSelected] = useState(null) 
  const [grid12, setGrid12Status] = useState(false) 
  const [products, setProducts] = useState([]) 
  const dispatch = useDispatch();
  const commandid = useSelector(state => state.commandid);
  const items = useSelector(state => state.items);
  const selectedModel = useSelector(state => state.selected);
  const digitizer = useSelector(state => state.digitizer);
  const gtm = useSelector(state => state.gtm);
  const timer = useSelector(state => state.timer);

  const TextEl = useRef(null)

  useEffect(() => {
    setSelected(selectedModel)
    setTimeout(() => { 
      setDisplay(false); 
      TweenMax.to(TextEl.current, 0.5,{ scale: 0, delay: 0, ease: "power2.inOut",
      onComplete : function(){
        setDisplay(true)
        setTimeout(() => { 
          // モデルを変更せずとも、5S後にはボタンを有効にする
          setMeasBtnDisabled(false)
        }, 500)
      }
    })
    } , 500 )

    setGrid12Status(items.length > 4 )

    let productNumber = null 
    let productlist =  []
    let productslist = []
    for ( const v in items ) {
      if ( productNumber != null && items[v].productNumber != productNumber) {
        productslist.push(productlist)
        productlist = []
      }
      productlist.push(items[v])
      productNumber = items[v].productNumber
    }
    productslist.push( productlist)
    setProducts(productslist)
  }, [])

  const hundleSelectModel = (model) => () => {
    if(isDisabled) return
    if(selected == model ) return
    setDisabled(true)
    setMeasBtnDisabled(true)
    try {
      const input = { 
        state: "TRYON", 
        store_code: digitizer,
        shoe_model:  model }

      action.createAppSync(input)
      setSelected(model)
      dispatch({ type:"SET_SELECTED", model })
      action.pushGtm(gtm, { state: input.state, operation: "SELECT", shoe_id : model} )
      action.updateTimer(timer, gtm, dispatch)
      setDisabled(false)
      setMeasBtnDisabled(false)
    } catch(e){
      dispatch({type: "SET_ERROR", error:e.message})
    }
  }

  const hundleNext = (scene) => () => {
    setDisabled(true)
    setMeasBtnDisabled(true)
    let gtm_operation = "NEXT"

    try{
      const input = { 
        state: "ACGUIDE", 
        store_code: digitizer,
        shoe_model:  selected
      }
      action.createAppSync(input)
      action.setScene("Guide", dispatch)
      dispatch({ type:"SET_SELECTED", selected: selected})
      action.pushGtm(gtm, { state: input.state, operation: gtm_operation, shoe_id :  input.shoe_model ? input.shoe_model : null } )
      action.updateTimer(timer, gtm, dispatch)
      setDisabled(false)
      setMeasBtnDisabled(false)
    }catch(e){
      dispatch({type: "SET_ERROR", error:e.message})
    }
  }

  const hundleBack = (scene) => () => {
    let gtm_operation = "RESET"
    setDisabled(true)
    try {
      let input = { 
        state: "STANDBY", 
        store_code: digitizer,
      }

      action.updateTimer(timer, gtm, dispatch)
      action.createAppSync(input)
      action.setScene("Top", dispatch)
      action.pushGtm(gtm, { state: input.state, operation: gtm_operation, shoe_id :  input.shoe_model ? input.shoe_model : null } )
      setDisabled(false)

    } catch (e) {
      dispatch({type: "SET_ERROR", error:e.message})
    }
  }



  return (
    <Root>
      { isDisplay && 
      <Items>
      { products.map((product, productindex ) => {
        return (
          <Product key={productindex}>

      <ModelTitle grid12={(grid12)}>
        <ModelName>{product[0].maker}/{product[0].productNumber}</ModelName>
        <ModelPrice>¥{product[0].price}(in tax)</ModelPrice>
      </ModelTitle>
      <List grid12={(grid12)} rows={Math.ceil(product.length/4)}>
      {product.map((item, i ) => {
          return (
            <li key={i} >
              <Thumbnail
                isDisabled={true}
                src={item.thumbnail}
                name={item.name}
                selected={(selected === item.model)}
                onSelect={hundleSelectModel(item.model)}
                grid12={grid12}
                height={"160px"}
              />
            </li>
          )
        })}
      </List>
          </Product>
      )}
      )}
      </Items>
      }

      { isDisplay && 
     <MeasurementButton 
        isDisabled={isMeasBtnDisabled}
        onTouchStart={hundleNext} scene={"Guide"}><img src={measBtnTextImg} width={220}/></MeasurementButton>
      }

      <TopButton 
        isDisabled={isDisabled}
        onTouchStart={hundleBack} scene={"Top"} >
          <img src={backBtnImg} width={200} />
      </TopButton>
    </Root>
  );
}

const Root = styled.div`
  position: relative;
  height: 100vh; /* 全画面表示 */
  width: 100vw; /* 全画面表示 */
`

const Footer = styled.div`
  position: absolute;
  bottom 0px;
  left: 0px;
  background: ${({ theme }) => theme.color.white};
  width: 100%;
  height: 140px;
`
const ModelTitle = styled.h3`
  margin-top: 10px;
  margin-left: ${({ grid12 }) => grid12 ? "20px" : "40px" };
  color: ${({ theme }) => theme.color.white};
`
const ModelName = styled.p`
display: inline;
  font-size: 1.8em;
  font-weight: bold;
  color: ${({ theme }) => theme.color.white};
`
const ModelPrice = styled.p`
  display: inline;
  margin-left: 40px;
  font-size:1.4em;
`
const Product = styled.div`
  margin-bottom: 30px;
`
const Items = styled.div`
  position: absolute;
  width: 100%;
  top: 90px;
`

const Close = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  background-color: #000;
  opacity: 0.6;
  width: 100%;
  height: 600px;
`


const List = styled.ul`
  background-color: #fff;
  display: grid;
  grid-template-columns: ${({ theme, grid12 }) => grid12 ? "1fr 1fr 1fr 1fr" : "1fr 1fr" };
  grid-template-rows: ${({ theme, rows }) => (rows==1) ? "1fr" : "1fr 1fr" };
  grid-gap: ${({ theme, grid12 }) => grid12 ? "5px" : "25px" };
  text-align: center;
  margin: ${({ grid12 }) => grid12 ? "0 10px" : "0" }; 
  padding: 10px;
  > li {
    margin: auto;
    max-height: 200px
  }
`
const MeasurementButton = styled(SceneButton)`
  width: 90%;
  text-align: center;
  position: absolute;
  bottom: 40px;
  left: 50%;
  transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  > p {
    > span {
      font-size: 0.7em;
      font-weight: bold;
    }
  }
`

const Buttons = styled.ul`
  position: absolute;
  bottom: 100px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center; /* 縦方向中央揃え（Safari用） */
  align-items: center; /* 縦方向中央揃え */
  -webkit-justify-content: center; /* 横方向中央揃え（Safari用） */
  justify-content: center; /* 横方向中央揃え */
  > li {

  }

`

const TopButton = styled(Button)`
  position: absolute;
  top: 40px;
  left: 35px;
`