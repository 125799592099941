import * as React from 'react'
import styled from 'styled-components'


export default function ButtonA(props) {
    return (
        <ButtonStyle  
          disabled={props.isDisabled}
          className={props.className} 
          onTouchStart={props.onTouchStart(props.scene)}>{props.children}</ButtonStyle>
    );
  }

const ButtonStyle = styled.button`
  border-radius: 20px;
  border: 1px solid #ffffff;
  padding: 5px 10px;
  font-size: 1.0rem;
  font-weight: bold;
  color: #ffffff;
  &:disabled {
    color: ${({ theme }) => theme.color.grey};
  }
  
`
