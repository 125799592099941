import React, {useState, useEffect, useRef} from 'react';
import { useSelector, useDispatch, createDispatchHook } from 'react-redux';
import uuid from 'react-uuid';
//import {getItems , setScene, getFdcode, createAppSync,  pushGtm, updateTimer } from '../actions/index';
import  * as action from '../actions/index';
import { TweenMax, TimelineMax } from "gsap"
import styled from 'styled-components';
import { ButtonCloud } from './buttons/ButtonCloud' 
import BackButton from './buttons/ButtonsA' 
import bgBtn from '../assets/images/btn_bg.svg'
import CharacterBear from './Character/CharacterBear'
import CharacterSheep from './Character/CharacterSheep'
import CharacterRabbit from './Character/CharacterRabbit'
import CharacterMouse from './Character/CharacterMouse'
import CharacterCat from './Character/CharacterCat'
import CharacterHorse from './Character/CharacterHorse';
import startGuide from '../assets/images/start_guide_text.svg'
import start from '../assets/images/start_text.svg'
import back from '../assets/images/back_btn.svg'
import { WindowsBalloon } from 'node-notifier';

export default function Top() {

  const [error, setError] = useState(null);
  const [isDisabled, setDisabled] = useState(false)
  const [isStartBtn, setStartBtn] = useState(true)
  const [digitizer, setStateDigitizer] = useState("");
  const [token, setStateToken] = useState("");
  const [type, setType] = useState("boy");
  const [name, setName] = useState("");
  const [stepText, setStepText] = useState("計測するお子さまは？(1/3)");
  const [age, setAge] = useState("");
  const [monthIndex, setMonthIndex] = useState(0);
  const [dateIndex, setDateIndex] = useState(0);

  const [monthList, setMonth] = useState([
    { label : "1月", value: "01"} ,
    { label : "2月", value: "02"} ,
    { label : "3月", value: "03"} ,
    { label : "4月", value: "04"} ,
    { label : "5月", value: "05"} ,
    { label : "6月", value: "06"} ,
    { label : "7月", value: "07"} ,
    { label : "8月", value: "08"} ,
    { label : "9月", value: "09"} ,
    { label : "10月", value: "10"} ,
    { label : "11月", value: "11"} ,
    { label : "12月", value: "12"} 
  ]);
  const [dateList, setDate] = useState([
    { label : "1日", value: "01"} ,
    { label : "2日", value: "02"} ,
    { label : "3日", value: "03"} ,
    { label : "4日", value: "04"} ,
    { label : "5日", value: "05"} ,
    { label : "6日", value: "06"} ,
    { label : "7日", value: "07"} ,
    { label : "8日", value: "08"} ,
    { label : "9日", value: "09"} ,
    { label : "10日", value: "10"} ,
    { label : "11日", value: "11"} ,
    { label : "12日", value: "12"} ,
    { label : "13日", value: "13"} ,
    { label : "14日", value: "14"} ,
    { label : "15日", value: "15"} ,
    { label : "16日", value: "16"} ,
    { label : "17日", value: "17"} ,
    { label : "18日", value: "18"} ,
    { label : "19日", value: "19"} ,
    { label : "20日", value: "20"} ,
    { label : "21日", value: "21"} ,
    { label : "22日", value: "22"} ,
    { label : "23日", value: "23"} ,
    { label : "24日", value: "24"} ,
    { label : "25日", value: "25"} ,
    { label : "26日", value: "26"} ,
    { label : "27日", value: "27"} ,
    { label : "28日", value: "28"} ,
    { label : "29日", value: "29"} ,
    { label : "30日", value: "30"} ,
    { label : "31日", value: "31"} 
  ]);

  const [isDialog, setDialog] = useState(false) 


  const [sessionUuid, setSessiontUuid] = useState("");

  const s = useSelector(state => state);
  const w = useSelector(state => state.window);
  const u = useSelector(state => state.userAttributes);
  const items = useSelector(state => state.items);
  const dispatch = useDispatch();
  const TopBtnEl = useRef(null)
  const TopBtnImageEl = useRef(null)
  const NextBtnEl = useRef(null)
  const YajirushiEl = useRef(null)
  const MouseEl = useRef(null)
  const RabbitEl = useRef(null)
  const HorseEl = useRef(null)
  const CatEl = useRef(null)
  const BearEl = useRef(null)
  const SheepEl = useRef(null)
  const NameEl  = useRef(null)
  const GenderEl  = useRef(null)
  const BirthEl  = useRef(null)

  const tlBottom = new TimelineMax({paused: false, repeat: -1, repeatDelay: 5});
  const tlTop = new TimelineMax({paused: false, repeat: -1, repeatDelay: 5});

  // Note: the empty deps array [] means
  // this useEffect will run once
  // similar to componentDidMount()
  useEffect(() => {
    dispatch({type: "SET_RETRY", retry:null})
    dispatch({type: "SET_ERROR", error:null})
    dispatch({type: "SET_CHARACTER", character:null})
    
    const f = async () => {
      action.pushSheet()
      setType(u.sex)
      try {
        const {fdcode, qptoken } = await action.getFdcode(dispatch)
        setStateDigitizer(fdcode)
        setStateToken(qptoken)

        action.getItems(fdcode, dispatch)

        // UUID生成
        const dateObj = new Date()
        const uuidDate = action.dateFormat.format(dateObj, 'yyyyMMddhhmmss')
        const serialDate = action.dateFormat.format(dateObj, 'yyyyMMdd')
        const key = 'serialnumber'
        const keyDate = 'keydate'
        let number = 0

        if (localStorage.getItem(key)) {
          if (localStorage.getItem(keyDate) && localStorage.getItem(keyDate) == serialDate ) {
            number = localStorage.getItem(key);
          }
        }        
        number++

        localStorage.setItem(key, number)
        localStorage.setItem(keyDate, serialDate)

        const serial = ( '0000' + number ).slice( -4 );
        const wkSessionUuid = `${fdcode}-${uuidDate}-${serial}`
        setSessiontUuid(wkSessionUuid)
        dispatch({type: 'SET_UUID', uuid: wkSessionUuid })
       
        const input = { store_code: fdcode , state: "STANDBY", token: qptoken};
        action.createAppSync(input)

        dispatch( { type: 'SET_RESETGTM'})
        dispatch({type: 'SET_GTM', store_code:fdcode, session_id: wkSessionUuid })
        const gtmData = {  
        'event': 'statepush', 
        'store_code': fdcode, 
        'session_id': wkSessionUuid, 
        'state': 'STANDBY',
        'operation': "READ",
        'foot_uuid': "",
        'shoe_id': "",
        'fit': null,
        'recommended_size': "",
        'created_at': action.dateFormat.format(new Date(), 'yyyy/MM/dd hh:mm:ss')
        }
        window.dataLayer.push(gtmData)
        //action.pushSheet(gtmData)
        action.pushLogLocalStorage(gtmData)
      } catch (e) {
        setDisabled(true)
        // エラーこはこの画面で表示
        setError(e.message)
        console.log(e)
        // 初期処理のエラーはAppsyncに送らない
        // TODO エラーメッセージをGTMにも送る
        const gtmData = { 
         'event': 'statepush', 
         'store_code': "", 
         'session_id': "", 
         'state': 'ERROR',
         'operation': "INIT",
         'foot_uuid': "",
         'shoe_id': "",
         'fit': null,
         'recommended_size': "",
         'created_at': action.dateFormat.format(new Date(), 'yyyy/MM/dd hh:mm:ss')
        }
        window.dataLayer.push(gtmData)
        //action.pushSheet(gtmData)
        action.pushLogLocalStorage(gtmData)
      }
    }

    /*
    TweenMax.from(TopBtnImageEl.current, 2, { repeatDelay: 5 , scale: 1.1, ease: "elastic.out(1, 0.2)", repeat: -1})
    tlBottom.to(BearEl.current, 1, 
      {bottom: -130, ease: "elastic"} ,
    ).to(SheepEl.current, 1 ,{bottom: -110, ease: "elastic" }, '-=0.5').to(RabbitEl.current, 1,{bottom: -80, ease: "elastic"}, '-=1');

    tlTop.to(MouseEl.current, 1, 
      {top: -55, ease: "elastic"} ,
    ).to(CatEl.current, 1 ,{top: -130, ease: "elastic" }, '-=0.5').to(HorseEl.current, 1,{top: -150, ease: "elastic"}, '-=1');
    */
    TweenMax.to(TopBtnEl.current, 2, { y:10, yoyo: true, repeat: -1})
    f()
    return () => {
      // Unmount時の処理を記述
      TopBtnImageEl.current = true
      YajirushiEl.current = true
    };
  }, [])

  function handleInputChange (e) {
    setName(e.target.value )
  }


  function handleAgeInputChange (e) {
    if (e.target.value.length > 2) {
      setAge(e.target.value.substring(0,2) )
      return
    }
    
    setAge(e.target.value )
  }

  function handleSelectMonthChange (e) {
    const index = monthList.findIndex( v => v.value == e.target.value);
    setMonthIndex(index)
  }

  function handleSelectDateChange (e) {
    const index = dateList.findIndex( v => v.value == e.target.value);
    setDateIndex(index)
  }

  const hundleNextName = (e) => {
    if(age == "" ) return
    setStepText("お子さまのお名前は？(3/3)")
    action.updateTimer(s.timer, s.gtm, dispatch)
    TweenMax.to(BirthEl.current, 1, { opacity: 0, left: w.width*-1 ,ease: "power2.inOut", onComplete : function(){
      TweenMax.to(NameEl.current, 1, { opacity: 1, left: 0 ,ease: "power2.inOut"})
    }})

    let ua = {...u}

    const date = new Date();
    let year = date.getFullYear();
    let todayMonth = date.getMonth() + 1;
    let todayDate = date.getDate();

    if ( todayMonth > monthList[monthIndex].value ) {
      year = year - parseInt(age)
    } else if ( todayMonth < monthList[monthIndex].value ) {
      year = year - ( parseInt(age) + 1 )
    } else  {
      //  今月に誕生日
      if ( todayDate >= dateList[dateIndex].value ) {
        year = year - parseInt(age)
      } else {
        //  誕生日がきていない
        year = year - ( parseInt(age) + 1 )
      }
    }

    ua.birthday = year+monthList[monthIndex].value+dateList[dateIndex].value
    ua.age = parseInt(age)
    console.log(ua)
    dispatch({type: "SET_USERATTRIBUTE", userAttributes: ua})

  }

  const selectGender = (g) => () => {  
    setType(g)
  }

  const hundleNextBirth = () => {
    setStepText("お子さまの年齢と誕生日は？(2/3)")
    action.updateTimer(s.timer, s.gtm, dispatch)
    let ua = {...u}
    ua.sex = type
    dispatch({type: "SET_USERATTRIBUTE", userAttributes: ua})
    TweenMax.to(GenderEl.current, 1, { opacity: 0, left: w.width*-1 ,ease: "power2.inOut", onComplete : function(){
      TweenMax.to(BirthEl.current, 1, { opacity: 1, left: 0 ,ease: "power2.inOut"})
    }})
  }

  const hundleErrorCancel = () => {
    console.log("HumdleErrorCancel")
  }
  


  const hundleNextStep = (e) => {
    // disabled(ニックネームみ入力)なら進まない
    if (e.target.disabled) return

    let ua = {...u}
    ua.name = name
    dispatch({type: "SET_USERATTRIBUTE", userAttributes: ua})

    if ( !items[0]) {
      dispatch({type: "SET_ERROR", error: "通信状況を確認してください", hundleErrorCancel: hundleErrorCancel})
      return 
    }  
    // キャラクターを固定
    dispatch({type: "SET_CHARACTER", character: "bear"})
    TweenMax.to(BirthEl.current, 1, { opacity: 0, left: w.width*-1 ,ease: "power2.inOut", onComplete : function(){
      // キャラクター選択を一旦外す
      //action.createAppSync({ store_code: digitizer , state: "ACSELECTCHARACTER", shoe_model: items[0].model })
      action.createAppSync({ store_code: digitizer  ,token: token, state: "ACSELECTCHARACTER", shoe_model: items[0].model, message : "bear" })
      action.pushGtm(s.gtm, { state: "ACSELECTCHARACTER", operation: "START", shoe_id: items[0].model} )
      dispatch({ type:"SET_SELECTED", selected:items[0].model })
      // キャラクター選択を一旦外す
      //action.setScene("Characters", dispatch)
      action.setScene("CharacterGuide", dispatch)
      setDisabled(false)
    }})
    action.updateTimer(s.timer, s.gtm, dispatch)
  }

  const hundleDialog = () => () => {
    setDialog(true)
  }

  
  const hundleChangeScene = (scene) => () => {  
    try {
      setDisabled(true)
      let input = { state: "STANDBY", store_code: digitizer}

      action.setScene("Done",  dispatch)
      action.createAppSync(input)

      setDisabled(false)
      action.pushGtm(s.gtm, { state: "STANDBY", operation: "BACK"} )
      action.updateTimer(s.timer, s.gtm, dispatch)
    } catch(e){
      dispatch({type: "SET_ERROR", error:e.message})
    }
  }


  return (
    <Root>
      <BearWrap ref={BearEl}  w={w.wrate}><CharacterBear w={w.wrate} /></BearWrap>
      {/* 
      
      <SheepWrap ref={SheepEl} w={w.wrate}><CharacterSheep w={w.wrate} /></SheepWrap>
      <RabbitWrap ref={RabbitEl}  w={w.wrate}><CharacterRabbit w={w.wrate}/></RabbitWrap>
      <MouseWrap ref={MouseEl} w={w.wrate}><CharacterMouse w={w.wrate} /></MouseWrap>
      <CatWrap ref={CatEl} w={w.wrate}><CharacterCat w={w.wrate} /></CatWrap>
      <HorseWrap ref={HorseEl} w={w.wrate}><CharacterHorse  w={w.wrate}/></HorseWrap>
      */}

      {error == null &&
        <div>

        <StartTxt>{stepText}</StartTxt>
        <Start>
          {/* 
        <div>
          <ButtonCloud ref={TopBtnEl} isabled={isDisabled}  hundleNext={hundleNextStep} src={bgBtn} imgwidth={500*w.wrate}>
          <img src={start} width={280*w.wrate}/>
          </ButtonCloud>
          <br />
          <StartGuide src={startGuide} widthRate={w.wrate}/>
        </div>
          */}
        
          <Name ref={NameEl} left={w.width}>
            <InputField  onChange={handleInputChange} value={name} />
            <UserAttributeBtn>
              <UserAttributeNext onTouchStart={hundleNextStep} disabled={(name.length <= 0 )}>→</UserAttributeNext>
              
            </UserAttributeBtn>
          </Name>

          <Type ref={GenderEl} left={w.width}>
            <GenderSelect selected={type == "boy"} onTouchStart={selectGender("boy")}>男の子</GenderSelect>
            <GenderSelect selected={type == "girl"} onTouchStart={selectGender("girl")}>女の子</GenderSelect>
            <UserAttributeBtn>
              <UserAttributeNext onTouchStart={hundleNextBirth}>→</UserAttributeNext>
            </UserAttributeBtn>
          </Type>

          <Birth ref={BirthEl} left={w.width}>
            <InputNumberField  onChange={handleAgeInputChange} value={age} maxlength="2" pattern="\d*" /><Sai>才</Sai>
            <BirthWrap>
            { monthList && 
            <Selected>
            <SelectedLabel >{monthList[monthIndex].label}</SelectedLabel>
              <SelectList onChange={handleSelectMonthChange} >
              {monthList.map((data) => {
                return <option key={data.value} value={data.value}>{data.label}</option>;
              })}
              </SelectList>
            </Selected>
            }

            { dateList && 
            <Selected>
            <SelectedLabel >{dateList[dateIndex].label}</SelectedLabel>
              <SelectList onChange={handleSelectDateChange} >
              {dateList.map((data) => {
                return <option key={data.value} value={data.value}>{data.label}</option>;
              })}
              </SelectList>
            </Selected>
            }
            <Umare>生</Umare>



            </BirthWrap>
            <UserAttributeBtn>
              <UserAttributeNext onTouchStart={hundleNextName} disabled={(age.length <= 0 )}>→</UserAttributeNext>
            </UserAttributeBtn>

          </Birth>
        </Start>
      <TopButton onTouchStart={hundleDialog}  scene={"Top"} >
        キャンセル
      </TopButton>
        </div>
      }

      {error != null &&
        <Start>
        <ErrorTxt>LINEのトークルームで<br />６桁の数字を入力してください</ErrorTxt>
        </Start>
      }


      {  isDialog && 
      <DialogRoot>
      <DialogWrapper>
        <dl>
          <dt>
            <i className="fas fa-exclamation-triangle" />
            <p>LINEで番号入力からやり直しになります</p>
            <p>キャンセルしてよろしいですか？？</p>
          </dt>
          <dd>
            <p>
              <DialogButton2 onTouchStart={() => setDialog(false)}>しない</DialogButton2>
              <DialogButton onTouchStart={() => window.location.reload(true)}>する</DialogButton>
            </p>
          </dd>
        </dl>
      </DialogWrapper>
      </DialogRoot>
    }
    </Root>
  );
}

const Root = styled.div`
  position: relative;
  min-height: 100vh; /* 全画面表示 */
  max-height: -webkit-fill-available;
  max-height: -moz-available;
  max-height: stretch;
  margin: 0;
  padding: 0;
`
const StartTxt = styled.div`
  position: absolute;
  top: 20%;
  font-size: 2.2rem;
  font-weight: bold;
  color: #fff;
  width: 100%;
  text-align: center;
`

const UserAttributeBtn  = styled.div`
  margin-top: 40px;
`

const UserAttributeNext = styled.button`
  width: 60px;
  height: 60px;
  line-height: 60px;
  background-color: ${({disabled}) =>  disabled ? "#cccccc" : "#F4C220" };
  border-radius: 50%;
  color: #fff;
  text-align: center;
  font-size: 3.0rem;
  font-weight: bold;
  position: absolute;
  right: 50px;
`

const Name = styled.div`
  opacity: 0;
  position: absolute;
  left: ${({left}) =>  left + "px"}; 
  top: 50%;
  transform:  translateY(-50%);
  -webkit-transform: translateY(-50%);
  width: 100%;
`
const InputTitle = styled.div`
  font-size: 2.0rem;
  font-weight: bold;
  width: 100%;
  text-align: center;
  font-weight: bold;
  color: #F4C220;
`
const InputField = styled.input.attrs({ type: 'text' })`
  margin-top: 40px;
  background: #ffffff;
  font-size: 1.4rem;
  font-weight: bold;
  color: #666666;
  cursor: pointer;
  text-transform: uppercase;
  width: 300px;
  border-radius: 30px;
  height: 60px;
  border-color: transparent;
  box-shadow: 0px;
  outline: none;
  transition: 0.15s;
  text-align: center;
  &:active {
    background-color: #f1ac15;
  }
`

const InputNumberField = styled.input.attrs({ type: 'number' })`
  margin-top: 40px;
  background: #ffffff;
  font-size: 1.4rem;
  font-weight: bold;
  color: #666666;
  cursor: pointer;
  text-transform: uppercase;
  width: 150px;
  border-radius: 30px;
  height: 60px;
  border-color: transparent;
  box-shadow: 0px;
  outline: none;
  transition: 0.15s;
  text-align: center;
  &:active {
    background-color: #f1ac15;
  }
`

const Sai = styled.span`
  margin-left: 10px;
  color: #F4C220;
  text-align: center;
  font-size: 1.6rem;
  font-weight: bold;
`
const Umare = styled.span`
  margin-left: 10px;
  color: #F4C220;
  text-align: center;
  font-size: 1.6rem;
  font-weight: bold;
  position: relative;
  top: 50%;
  transform: translateY(25%);
  -webkit-transform: translateY(25%);
`


const Type = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  width: 100%;
`
const GenderSelect = styled.button`
  margin: 0 20px;
  background: ${({selected}) =>  selected ? "#F4C220" : "#ffffff"}; 
  font-size: 1.4rem;
  font-weight: bold;
  color: ${({selected}) =>  selected ? "#ffffff" : "#666666"}; 
  cursor: pointer;
  text-transform: uppercase;
  width: 100px;
  border-radius: 30px;
  height: 60px;
  border-color: transparent;
  box-shadow: 0px;
  outline: none;
  transition: 0.15s;
  text-align: center;
`
const Birth = styled.div`
  position: absolute;
  opacity: 0;
  left: ${({left}) =>  left + "px"}; 
  top: 50%;
  transform:  translateY(-50%);
  -webkit-transform: translateY(-50%);
  width: 100%;
`

const BirthWrap = styled.div`
  position: relative;
  width: 3000px;
  left: 50%;
  transform:  translateX(-140px);
  -webkit-transform: translateX(-140px);
  display: flex;
  margin: 20px 0;
`
const Selected = styled.div`
width: 100px;
margin: 0 10px;
position: relative;
background: ${({selected}) =>  selected ? "#F4C220" : "#ffffff"}; 
border-radius: 6px;
border-radius: 30px;
height: 60px;
font-size: 1.4rem;
font-weight: bold;
`


const SelectedLabel = styled.p`
color: ${({selected}) =>  selected ? "#ffffff" : "#666666"}; 
position: absolute;
width: 100%;
z-index: 1;
top: 50%;
    transform: translateY(-50%);
`


const SelectList = styled.select`
-webkit-appearance: none;
-moz-appearance: none;
     appearance: none;
display: block;
cursor: pointer;
width: 100%;
border: none;
padding: 20px;
opacity: 0;
position: relative;
z-index: 2;
`




const Button = styled.button`
  position: relative;
  text-align: center;
`
const StartGuide = styled.img`
  margin-top: 50px;
  width: ${({ widthRate}) =>  (378 * widthRate) + "px"};
`
const Btntxt = styled.span`
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  font-size: 4.0rem;
  font-weight: bold;
  color: #767676;
`

const ErrorTxt = styled.div`
  font-size: 2.0rem;
  font-weight: bold;
  color: #ffffff
`



const Start = styled.div`
position: fixed;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  width: 100%;
  text-align: center;
`

const TopButton = styled(BackButton)`
  position: absolute;
  top: 20px;
  left: 10px;
`
const CatWrap = styled.div`
  position: fixed;
  top: ${({ w}) =>  (-300 * w) + "px"};
  left: ${({ w}) =>  (-85 * w) + "px"};
`
const HorseWrap = styled.div`
  position: fixed;
  top: ${({ w}) =>  (-550 * w) + "px"};
  right: ${({ w}) =>  (-150 * w) + "px"};
`
const BearWrap = styled.div`
position: fixed;
  bottom: ${({ w}) =>  (-100 * w) + "px"};
  left: 50%;
  transform: translateX(-50%) scale(1,1);
  -webkit-transform: translateX(-50%) scale(1,1);
`
const SheepWrap = styled.div`
  position: fixed;
  bottom: ${({ w}) =>  (-330 * w) + "px"};
  left: ${({ w}) =>  (-130 * w) + "px"};
`

const RabbitWrap = styled.div`
  position: fixed;
  bottom: ${({ w}) =>  (-300 * w) + "px"};
  right: ${({ w}) =>  (-100 * w) + "px"};
`
const MouseWrap = styled.div`
  position: fixed;
  top: ${({ w}) =>  (-230 * w) + "px"};
  left: 50%;
  transform: translateX(-50%) scale(1,1);
  -webkit-transform: translateX(-50%) scale(1,1);
`

const DialogRoot = styled.div`
position: fixed;
top: 0;
left: 0;
width: 100vw;
height: 100vh;
display: flex;
justify-content: center;
align-items: center;
background-color: rgba(0, 0, 0, 0.7);
z-index: 99999;
`

const DialogWrapper = styled.div`
width: 80%;
background-color: #fff;
border-radius: 15px;
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
padding: 10px;
font-size: 1.0rem;
dl {
  text-align: center;
}
dt {
  color: #cc0000;
  font-size: 14px;
  i {
    margin-right: 10px;
  }
}
p {
  margin: 20px 0;
}
`

const DialogButton = styled.button`
height: 50px;
min-width: 100px;
font-weight: bold;
  font-size: 14px;
border-radius: 25px;
margin: 0 10px;
color: ${({ theme }) => theme.color.white };
background-color: #F4C220;
`

const DialogButton2 = styled.button`
height: 50px;
min-width: 100px;
font-weight: bold;
  font-size: 14px;
border-radius: 25px;
margin: 0 10px;
color: ${({ theme }) => theme.color.white };
background-color: ${({ theme }) => theme.color.grey2 };
`