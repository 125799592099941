import React, {useState, useEffect, useRef} from 'react';
import { Transition } from 'react-spring/renderprops'
import styled from 'styled-components'
import { TweenMax, TimelineMax } from "gsap"



import body from './assets/bear/bear_body_nocap.svg'
import cap from './assets/bear/bear_cap.svg'
import leftHand from './assets/bear/bear_hand_left.svg'
import rightHand from './assets/bear/bear_hand_right.svg'


const tl = new TimelineMax({paused: false, repeat: -1});

export default function CharacterBear(props) {
  const BodyEl = useRef(null)
  const LeftEl = useRef(null)
  const RightEl = useRef(null)
  const CapEl = useRef(null)


  useEffect(() => {
    // Update the document title using the browser API


    tl.fromTo(LeftEl.current, 0.5, 
      { rotation:  25, transformOrigin:"30% 50% 0"},
      { rotation: '-=30', transformOrigin:"30% 50% 0", repeat: -1 }
    ).fromTo(RightEl.current, 0.5, 
      { rotation:  -25, transformOrigin:"70% 50% 0"},
      { rotation: '+=30', transformOrigin:"70% 50% 0", repeat: -1 },
      '-=0.5'
    )

    TweenMax.fromTo(CapEl.current, 1.5, 
      { top:  0 },
      { top: '-=30', repeat: -1, ease: "elastic.inOut(1,0.3)"  }
    )

  });



    return (
        <Charcter w={props.w}>
            <Left ref={LeftEl} src={leftHand} width={108*props.w}  w={props.w}/>
            <Right ref={RightEl} src={rightHand} width={99*props.w}  w={props.w}/>
            <Body ref={BodyEl} src={body} width={300*props.w}  w={props.w}/>
            <Cap ref={CapEl} src={cap} width={95*props.w}  w={props.w}/>
        </Charcter>
    );
  }

const Charcter = styled.div`
  position: relative;
  width: ${({ w}) =>  (400 * w) + "px"};
  height: ${({ w}) =>  (400 * w) + "px"};
`
const Body = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
`
const Left = styled.img`
  position: absolute;
  right: ${({ w}) =>  (55 * w) + "px"};
  top: 50%;
`
const Right = styled.img`
  position: absolute;
  left: ${({ w}) =>  (55 * w) + "px"};
  top: 50%;
`
const Cap = styled.img`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
`