
import { GoogleSpreadsheet } from 'google-spreadsheet'
import CREDIT from '../minimumstoreSheetAuth.json';
// スプレッドシートキー
const SPREADSHEET_KEY = '1oeyIAZgjfLqbT4EouVDqsGfU_S17X81TjZLlXjGsqmI'

export async function  SpreadSheetService  () {
    const doc = new GoogleSpreadsheet(SPREADSHEET_KEY);
    // サービスアカウントによる認証
    await doc.useServiceAccountAuth({
      client_email: CREDIT.client_email,
      private_key: CREDIT.private_key,
    })
    return doc
}

/**
  * 行データを返す
  * @param {*} index 
*/
export async function getRows(doc, index) {
  await doc.loadInfo(); 
  const sheet = doc.sheetsByIndex[index]
  return sheet.getRows();
}

/**
* 行を追加する
* @param {*} value 
*/
export async function insert(doc, value) {
      await doc.loadInfo(); 
      const sheet = doc.sheetsByIndex[0]
      return await sheet.addRow(value);
}

/**
* 行を追加する
* @param {*} values 
*/
export async function insertRows(doc, values) {
      await doc.loadInfo(); 
      const sheet = doc.sheetsByIndex[0]
      console.log(sheet)
      return await sheet.addRows(values);
}
  /**
   * データを取得する
   * @param {*} callBack 
  async select(callBack) {
      const rows = await this.getRows(0)
      const data = []
      for (const row of rows) {
          if (callBack(row)) {
              data.push({id: row.id, name: row.name, age:row.age})
          }
      }
      return data
  }
   */
  /** 
   * idに紐づくユーザーの情報を更新する
  async updateById(id, value) {
      const rows = await this.getRows(0);
      for (const row of rows) {
          if (row.id == id) {
              for (const attr in value) {
                  row[attr] = value[attr]
                  await row.save()
              }
          }
      }
  }
  */
  /**
   * idに紐づくユーザーを削除する
   * @param {*} id 
  async deleteById(id) {
      const rows = await this.getRows(0);
      for (const row of rows) {
          if (row.id == id) {
              await row.delete()
          }
      }
  }
}
   */

