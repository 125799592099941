import React, {useState, useEffect, useRef} from 'react';
import { Transition } from 'react-spring/renderprops'
import styled from 'styled-components'
import { TweenMax, TimelineMax } from "gsap"



import body from './assets/rabbit/rabbit_body.svg'
import right from './assets/rabbit/rabbit_hand_right.svg'


const tl = new TimelineMax({paused: false, repeat: -1});

export default function CharacterRabbit(props) {
  const BodyEl = useRef(null)
  const RightEl = useRef(null)


  useEffect(() => {
    // Update the document title using the browser API


    tl.fromTo(RightEl.current, 1.5, 
      { rotation:  20, transformOrigin:"50% 50% 0"},
      { rotation: '-=30', transformOrigin:"50% 50% 0", ease:"slow(0.7, 0.7, false)" }
    ).fromTo(RightEl.current, 1.5, 
      { scale:  0.9},
      { scale: 1.2, ease:"slow(0.7, 0.7, false)" },
      "-=1.5"
    )

    });



    return (
        <Charcter w={props.w}>
            <Right ref={RightEl} src={right} width={108*props.w} w={props.w}/>
            <Body ref={BodyEl} src={body} width={300*props.w}  w={props.w}/>
        </Charcter>
    );
  }

const Charcter = styled.div`
  position: relative;
  width:  ${({ w}) =>  (400 * w) + "px"};
  height: ${({ w}) =>  (400 * w) + "px"};
`
const Body = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
`
const Right = styled.img`
  position: absolute;
  bottom: ${({ w}) =>  (50 * w) + "px"};
  left: ${({ w}) =>  (100 * w) + "px"};
`