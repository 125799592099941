import * as React from 'react'
import { Transition } from 'react-spring/renderprops'
import styled from 'styled-components'
import checkPng from '../../assets/images/check.png'


export default function CaracterThumbnail(props) {
    const selected = props.selected
    return (
        <ThumbStyle 
            className={props.className} onTouchStart={props.onSelect}>
            <ImageMask bg={props.bg}>
              {props.selected && 
                <ImageSelected src={props.srcSelected}  selectedPosition={props.selectedPosition}  />
              } 
              {!props.selected && 
                <Image src={props.src}  />
              } 
            </ImageMask >
          <Name selected={selected} grid12={props.grid12}>
              {props.selected && 
                <img src={props.srcTxtSelected} height={22} />
              } 
              {!props.selected && 
                <img src={props.srcTxt} height={22} />
              } 



          </Name>


        </ThumbStyle>
    );
  }

const ThumbStyle = styled.div`
  position: relative;
/*
background: ${({ theme, isDisabled }) => isDisabled ? theme.color.key: theme.color.white};
*/
`
const ImageMask = styled.div`
width: 200px;
height: 200px;
line-height: 200px;
border-radius: 50%;
background-color: #fff;
background-image: url( ${({ theme, bg }) => bg });
background-size: 200px 200px;
`
const Image = styled.img`
position: absolute;
top: 0;
left: 0;
`
const ImageSelected = styled.img`
position: absolute;
top: ${({ theme, selectedPosition}) =>  selectedPosition };
left: 0;
`



const CheckIcon = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -22px 0 0 -22px;
  pointer-events: none;
`

const Name = styled.div`
  color:#000;
  font-size: 1.8em;
  font-weight: bold;
  margin: 0 auto 0;
  width: 200px;
  padding: 5px;
`
