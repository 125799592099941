import React, {useState, useEffect, useRef} from 'react';
import { useSelector, useDispatch } from 'react-redux';
//import { setScene, getFootUuid, scan, watchMeasurement, getJisSize, updateAppSync, createAppSync, updateTimer, pushGtm, getRecommend} from '../actions/index'
import * as action from '../actions/index'
import { formatSize} from '../helpers/number'
import { TweenMax } from "gsap"

import styled from 'styled-components'
import BackButton from './buttons/ButtonsA' 
import config from '../config';
import backBtnImg from '../assets/images/back_btn.svg'

export default function Mesurement() {

  const [isDisabled, setStateDisabled] = useState(false)
  const [isDialog, setDialog] = useState(false)

  const s = useSelector(state => state);
  const w = useSelector(state => state.window);
  const digitizer = useSelector(state => state.digitizer);
  const token = useSelector(state => state.token);
  const footUuid = useSelector(state => state.footuuid);
  const userAttributes = useSelector(state => state.userAttributes);
  const uuid = useSelector(state => state.uuid);
  const selectedModel = useSelector(state => state.selected)
  const gtm = useSelector(state => state.gtm)
  const items = useSelector(state => state.items)
  const timer = useSelector(state => state.timer)
  const dispatch = useDispatch();
  const circleEl = useRef(null)
  const LoadEl = useRef(null)

  // Note: the empty deps array [] means
  // this useEffect will run once
  // similar to componentDidMount()
  useEffect(() =>  {




    const f = async () => {
      await action.tokenCheck(token)

      try {
        //const footUuid =  await action.getFootUuid(s.digitizer, dispatch)
        //await action.scan(footUuid)

        let polingStateInput = { 
          state: "ACMEASURING", 
          store_code: s.digitizer,
          token: s.token,
          option: footUuid,
          shoe_model: selectedModel,
          message: ""
        }
        
        const meas =  await action.watchMeasurement(footUuid, polingStateInput, dispatch)
        await action.getJisSize(footUuid, dispatch)
        await action.setUserAttributes(footUuid, token,userAttributes)

        // Recommend
        let size = meas.left.footMeasurement.footLength
        let width = meas.left.footMeasurement.footBoundingWidth
        if (size <  meas.right.footMeasurement.footLength ) {
          size = meas.right.footMeasurement.footLength
          width = meas.right.footMeasurement.footBoundingWidth
        }

        const selectItem = items.filter(item => item.model == selectedModel)
        const recommendSize = await action.getRecommend(size, width, selectItem[0].productNumber, dispatch )
        
        if( recommendSize != 'no_matches' ) {
          let variant = selectItem[0].size.filter( s =>  formatSize(s.jp)  == recommendSize )
          const footUuidBase64 =  window.btoa(footUuid)
          variant = variant.length > 0 ? `?variant=${variant[0].variant}&session_id=${uuid}&footid=${footUuidBase64}` : `?session_id=${uuid}&footid=${footUuidBase64}`
          // ECURL
          const path = selectItem[0].ecProductName.replace("/","-")
          dispatch({type: "SET_EC", ec: `${config.EC}/${path}${variant}`}) 
        } else {
          const path = selectItem[0].ecProductName.replace("/","-")
          dispatch({type: "SET_EC", ec: `${config.EC}/${path}`}) 
        }

      } catch (e) {
        console.log("== ERROR MESSAGE ==", e.message)
        if (e.sendstate) {
           action.createAppSync({ 
              state: "ACERROR", 
              store_code : digitizer, 
              token : s.token, 
              error_code : e.code, 
              message: e.message,
              shoe_model: selectedModel})
          dispatch({type: "SET_RETRY", retry:e.message})
        } else {
          dispatch({type: "SET_ERROR", error:e.message})
        }
        action.pushGtm(gtm, { state: "ERROR", operation: "MEASUREMENT", shoe_id : selectedModel } )
      }

      // 結果ページへ
      action.updateTimer(timer, gtm, dispatch)
      action.setScene("Result", dispatch)
    }

    TweenMax.to(LoadEl.current, 0.5,{ scale: 1, delay: 0, ease: "power2.inOut"})
    TweenMax.to(circleEl.current, 2, { rotation:360, transformOrigin:"50% 50%", repeat: -1, ease: "power2.inOut"})
    // ポーリングのタイムアウトの可能性回避用
    action.updateTimerMeasurement(timer, gtm, dispatch)
    f()

  }, [])

  const hundleChangeScene = (scene) => () => {
    setStateDisabled(true)
    let input = { state: "STANDBY", store_code: digitizer, token: s.token}
    action.createAppSync(input)
    action.setScene(scene, dispatch)
    action.pushGtm(gtm, { state: input.state, operation: "RESET", shoe_id : selectedModel } )
    action.updateTimer(timer, gtm, dispatch)
    setStateDisabled(false)
  }


  const hundleDialog = () => () => {
    setDialog(true)
  }

  return (
    <Root>
      <Text>
        計測中
        <br />
        <span>計測が完了するまで画面はそのままで待っててね</span>
      </Text>
      <TopButton disabled={isDisabled}  onTouchStart={hundleDialog}    scene={"Top"} >
        キャンセル
      </TopButton>

      {  isDialog && 
      <DialogRoot>
      <DialogWrapper>
        <dl>
          <dt>
            <i className="fas fa-exclamation-triangle" />
            <p>LINEで番号入力からやり直しになります</p>
            <p>キャンセルしてよろしいですか？？</p>
          </dt>
          <dd>
            <p>
              <DialogButton2 onTouchStart={() => setDialog(false)}>しない</DialogButton2>
              <DialogButton onTouchStart={() => window.location.reload(true)}>する</DialogButton>
            </p>
          </dd>
        </dl>
      </DialogWrapper>
      </DialogRoot>
    }


    </Root>
  );
}

const Root = styled.div`
  position: relative;
  min-height: 100vh; /* 全画面表示 */
  max-height: -webkit-fill-available;
  max-height: -moz-available;
  max-height: stretch;
  width: 100vw; /* 全画面表示 */
`
const Text = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  width: 100%;
  text-align: center;
  font-size: 2.5rem;
  font-weight: bold;
  color: #fff;
  span {
    margin-top: 10px;
    font-size: 1.4rem;
    font-weight: bold;
    color: #fff;
  }
`


const Measuring = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%) scale(0,0);;
  -webkit- transform: translateY(-50%) translateX(-50%) scale(0,0);
`
const Inner = styled.div`
  position: relative;
  width: 450px;
`
const MainText = styled.p`
  font-weight: bold;
  font-size: 6.0em;
  color: ${({ theme }) => theme.color.grey2};
`
const SubText = styled.p`
  color: ${({ theme }) => theme.color.key};
  font-size: 2.0em;
`

const TopButton = styled(BackButton)`
  position: absolute;
  top: 20px;
  left: 10px;
`

const Header = styled.ul`
  position: absolute;
  top: 0px;
  left: 0px;
  background: ${({ theme }) => theme.color.grey};
  width: 100%;
  height: 190px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center; /* 縦方向中央揃え（Safari用） */
  align-items: center; /* 縦方向中央揃え */
  -webkit-justify-content: center; /* 横方向中央揃え（Safari用） */
  justify-content: center; /* 横方向中央揃え */
  > li  {
    width: 200px;

  }
`

const Stepon = styled.dl`
  text-align: center;
    > dt {
      width: 42px;
      height: 42px;
      border-radius: 50%;
      background: ${({ theme }) => theme.color.key};
      text-align: center;
      color: #fff;
      margin: auto;
      font-size: 1.8em;
      font-weight: bold;
    }
    > dd {
      margin-top: 5px;
      font-size: 1.5em;
      font-weight: bold;


    }
`
const Stepoff = styled.dl`
  text-align: center;
    > dt {
      width: 42px;
      height: 42px;
      border-radius: 50%;
      background: ${({ theme }) => theme.color.grey7};
      text-align: center;
      color: #fff;
      margin: auto;
      font-size: 1.8em;
      font-weight: bold;
    }
    > dd {
      color: ${({ theme }) => theme.color.grey4};
      margin-top: 5px;
      font-size: 1.5em;
      font-weight: bold;

    }
`
const DialogRoot = styled.div`
position: fixed;
top: 0;
left: 0;
width: 100vw;
height: 100vh;
display: flex;
justify-content: center;
align-items: center;
background-color: rgba(0, 0, 0, 0.7);
z-index: 99999;
`

const DialogWrapper = styled.div`
width: 80%;
background-color: #fff;
border-radius: 15px;
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
padding: 10px;
font-size: 1.0rem;
dl {
  text-align: center;
}
dt {
  color: #cc0000;
  font-size: 14px;
  i {
    margin-right: 10px;
  }
}
p {
  margin: 20px 0;
}
`

const DialogButton = styled.button`
height: 50px;
min-width: 100px;
font-weight: bold;
  font-size: 14px;
border-radius: 25px;
margin: 0 10px;
color: ${({ theme }) => theme.color.white };
background-color: #F4C220;
`

const DialogButton2 = styled.button`
height: 50px;
min-width: 100px;
font-weight: bold;
  font-size: 14px;
border-radius: 25px;
margin: 0 10px;
color: ${({ theme }) => theme.color.white };
background-color: ${({ theme }) => theme.color.grey2 };
`