import React, {useState, useEffect, useRef} from 'react';
import { Transition } from 'react-spring/renderprops'
import styled from 'styled-components'
import { TweenMax, TimelineMax } from "gsap"



import body from './assets/sheep/sheep_body.svg'
import hair from './assets/sheep/sheep_hair.svg'


const tl = new TimelineMax({paused: false, repeat: -1});

export default function CharacterSheep(props) {
  const BodyEl = useRef(null)
  const HairEl = useRef(null)


  useEffect(() => {
    // Update the document title using the browser API


    tl.fromTo(HairEl.current, 1, 
      { rotation:  20, transformOrigin:"50% 50% 0"},
      { rotation: '-=30', transformOrigin:"50% 50% 0" }
    ).fromTo(HairEl.current, 1, 
      { rotation:  -10, transformOrigin:"50% 50% 0"},
      { rotation: '+=30', transformOrigin:"50% 50% 0" }
    )

  });



    return (
        <Charcter w={props.w}>
            <Hair ref={HairEl} src={hair} width={108*props.w}  w={props.w}/>
            <Body ref={BodyEl} src={body} width={300*props.w}  w={props.w}/>
        </Charcter>
    );
  }

const Charcter = styled.div`
  position: relative;
  width: ${({ w}) =>  (400 * w) + "px"};
  height: ${({ w}) =>  (400 * w) + "px"};
`
const Body = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
`
const Hair = styled.img`
  position: absolute;
  top: ${({ w}) =>  (10 * w) + "px"};
  right: 10%;
  transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
`