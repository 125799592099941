import React, {useState, useEffect, useRef} from 'react';
import { Transition } from 'react-spring/renderprops'
import styled from 'styled-components'
import { TweenMax, TimelineMax } from "gsap"



import body from './assets/cat/cat_body.svg'
import ribbon from './assets/cat/cat_ribbon.svg'


const tl = new TimelineMax({paused: false, repeat: -1, repeatDelay: 2});

export default function CharacterCat(props) {
  const BodyEl = useRef(null)
  const RibbonEl = useRef(null)


  useEffect(() => {
    // Update the document title using the browser API


    TweenMax.fromTo(RibbonEl.current, 1.5, 
      { y:  0, x: 0},
      { y: '+=30',x: "+=30", repeat: -1, ease: "bounce.out",yoyo: true  }
    )


    /*
   tl.to(RibbonEl.current, 1, 
    { x: 0, y: 0  },
    { x: "+=30", y: "+=30",  ease:"bounce.out", yoyo: true,  repeat: -1, repeatDelay: 2}
  )
    */
  

  });



    return (
        <Charcter>
            <Body ref={BodyEl} src={body} width="300" />
            <Ribbon ref={RibbonEl} src={ribbon} width="95" />
        </Charcter>
    );
  }

const Charcter = styled.div`
  position: relative;
  width: 400px;
  height: 400px;
`
const Body = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
`
const Ribbon = styled.img`
  position: absolute;
  top: 255px;
  left: 220px;
`