import * as React from 'react'
import styled from 'styled-components'


export const  ButtonCloud  = React.forwardRef((props, ref) => {
    return (
      <Button ref={ref} isabled={props.isDisabled}  onTouchStart={props.hundleNext}>
      <Btnimg src={props.src} width={props.imgwidth}/>
        <Btntxt>
          {props.children}
        </Btntxt>
      </Button>
    );
})

const Button = styled.button`
  position: relative;
  text-align: center;
`
const Btnimg = styled.img`
`
const Btntxt = styled.div`
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  font-size: 3.0rem;
  font-weight: bold;
  color: #1CABC1;
`


