import React, {useState} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as action from '../actions/index'
import BackButton from './buttons/ButtonsA'   
import styled from 'styled-components'

import Thumbnail from './Character/CharacterThumbnail'

import cat from '../assets/images/selectcharacter_cat.png'
import rabbit from '../assets/images/selectcharacter_rabbit.png'
import mouse from '../assets/images/selectcharacter_mouse.png'
import horse from '../assets/images/selectcharacter_horse.png'
import bear from '../assets/images/selectcharacter_bear.png'
import sheep from '../assets/images/selectcharacter_sheep.png'

import catSlected from '../assets/images/selectcharacter_cat_selected.png'
import rabbitSelected from '../assets/images/selectcharacter_rabbit_selected.png'
import mouseSelected from '../assets/images/selectcharacter_mouse_selected.png'
import horseSelected from '../assets/images/selectcharacter_horse_selected.png'
import bearSelected from '../assets/images/selectcharacter_bear_selected.png'
import sheepSelected from '../assets/images/selectcharacter_sheep_selected.png'

import catTxt from '../assets/images/selectcharacter_cat_text.svg'
import rabbitTxt from '../assets/images/selectcharacter_rabbit_text.svg'
import mouseTxt from '../assets/images/selectcharacter_mouse_text.svg'
import horseTxt from '../assets/images/selectcharacter_horse_text.svg'
import bearTxt from '../assets/images/selectcharacter_bear_text.svg'
import sheepTxt from '../assets/images/selectcharacter_sheep_text.svg'

import catTxtSelected from '../assets/images/selectcharacter_cat_text_selected.svg'
import rabbitTxtSelected from '../assets/images/selectcharacter_rabbit_text_selected.svg'
import mouseTxtSelected from '../assets/images/selectcharacter_mouse_text_selected.svg'
import horseTxtSelected from '../assets/images/selectcharacter_horse_text_selected.svg'
import bearTxtSelected from '../assets/images/selectcharacter_bear_text_selected.svg'
import sheepTxtSelected from '../assets/images/selectcharacter_sheep_text_selected.svg'

import decidedBearTxt from '../assets/images/decided_bear_text.svg'
import decidedCatTxt from '../assets/images/decided_cat_text.svg'
import decidedTxt from '../assets/images/decided_text.svg'

import text from '../assets/images/selectcharacter_text.svg'
import backBtnImg from '../assets/images/back_btn.svg'
import { DEFAULT_MAX_TRANSACTION_DURATION_SECONDS } from '@sentry/tracing/dist/browser/browsertracing';

export default function Characters() {
  console.log("Characters!!!")
  const [isDisabled, setDisabled] = useState(false) 
  const [selected, setSelected] = useState(null) 
  const digitizer = useSelector(state => state.digitizer);
  const gtm = useSelector(state => state.gtm);
  const selectedModel = useSelector(state => state.selected); 
  const character = useSelector(state => state.character); 
  const timer = useSelector(state => state.timer); 
  const s = useSelector(state => state);

  const dispatch = useDispatch();

  const hundleChangeScene = (scene) => () => {
    let gtm_operation = "BUTTON"
    setDisabled(true)
    try{
      const input = { 
        state: "ACSELECTCHARACTER", 
        store_code:  digitizer,
        }

      switch (scene) {
        case "Top":
          input.state = "STANDBY"
          gtm_operation = "RETRY"
          break;
        case "CharacterGuide":
          input.message = character
          gtm_operation = "ACSELECTCHARACTER2"
          break;
        case "Tryon":
        defualt:
          input.shoe_model = selectedModel 
          break;
      }

      action.createAppSync(input)
      action.setScene(scene, dispatch)
      action.pushGtm(gtm, { state: input.state, operation: gtm_operation, shoe_id : input.shoe_model ? input.shoe_model : null} )
      action.updateTimer(timer, gtm, dispatch)
      setDisabled(false)
    } catch(e) {
      dispatch({type: "SET_ERROR", error:e.message})
    }
  }

  const hundleSelectCharacter = (character) => () => {
    if(isDisabled) return
    if(selected == character ) return
    setDisabled(true)
    try {
      dispatch({type: "SET_CHARACTER", character: character})
      setSelected(character)
      setDisabled(false)
      action.updateTimer(s.timer, s.gtm, dispatch)


      let gtm_operation = "BUTTON"
      const input = { 
        state: "ACSELECTCHARACTER", 
        message : character,
        store_code:  digitizer
      }
      gtm_operation = "ACSELECTCHARACTER2"
      action.createAppSync(input)
      console.log(input)
      action.setScene("CharacterGuide", dispatch)
      action.pushGtm(gtm, { state: input.state, operation: gtm_operation, shoe_id : input.shoe_model ? input.shoe_model : null} )
      setDisabled(false)
      //hundleChangeScene("CharacterGuide")()
    } catch(e){
      dispatch({type: "SET_ERROR", error:e.message})
    }
  }


  return (
    <Root>
      <Title src={text} />

      <List>
        <li key="bear">
          <Thumbnail
            bg="/assets/images/bg_bear.png"
            isDisabled={true}
            src={bear}
            srcSelected={bearSelected}
            srcTxt={bearTxt}
            srcTxtSelected={bearTxtSelected}
            name="くまさん"
            selected={(selected === 'bear')}
            selectedPosition="-10px"
            onSelect={hundleSelectCharacter("bear")}
          />
        </li>
        <li key="cat">
          <Thumbnail
            bg="/assets/images/bg_cat.png"
            isDisabled={true}
            src={cat}
            srcSelected={catSlected}
            srcTxt={catTxt}
            srcTxtSelected={catTxtSelected}
            name="ねこさん"
            selected={(selected === 'cat' )}
            onSelect={hundleSelectCharacter('cat')}
          />
        </li>
        {/**
         * 
        <li key="rabbit">
          <Thumbnail
            bg="/assets/images/bg_rabbit.png"
            isDisabled={true}
            src={rabbit}
            srcSelected={rabbitSelected}
            srcTxt={rabbitTxt}
            srcTxtSelected={rabbitTxtSelected}
            name='うさぎさん'
            selected={(selected === "rabbit")}
            selectedPosition="-30px"
            onSelect={hundleSelectCharacter("rabbit")}
          />
        </li>
        <li key="sheep" >
          <Thumbnail
            bg="/assets/images/bg_sheep.png"
            isDisabled={true}
            src={sheep}
            srcSelected={sheepSelected}
            srcTxt={sheepTxt}
            srcTxtSelected={sheepTxtSelected}
            name="ひつじさん"
            selected={(selected === 'sheep')}
            selectedPosition="-30px"
            onSelect={hundleSelectCharacter('sheep')}
          />
        </li>
        <li key="mouse" >
          <Thumbnail
            bg="/assets/images/bg_mouse.png"
            isDisabled={true}
            src={mouse}
            srcSelected={mouseSelected}
            srcTxt={mouseTxt}
            srcTxtSelected={mouseTxtSelected}
            name="ねずみさん"
            selected={(selected === 'mouse')}
            selectedPosition="25px"
            onSelect={hundleSelectCharacter('mouse')}
          />
        </li>
        <li key="horse" >
          <Thumbnail
            bg="/assets/images/bg_horse.png"
            isDisabled={true}
            src={horse}
            srcSelected={horseSelected}
            srcTxt={horseTxt}
            srcTxtSelected={horseTxtSelected}
            name='うまさん'
            selected={(selected === 'horse')}
            selectedPosition="-45px"
            onSelect={hundleSelectCharacter('horse')}
          />
        </li>
         */}
      </List>

      
      {/**
       * 
      { character && 
      <Decided>
        <img src={decidedTxt} width={300} />
        <DecidedButton onTouchStart={hundleChangeScene("CharacterGuide")}>
          { character == "bear" && 
            <img src={decidedBearTxt} />
          }
          { character == "cat" && 
            <img src={decidedCatTxt} />
          }
        </DecidedButton>
      </Decided>
      }
       */}

      <TopButton 
        isDisabled={isDisabled} 
        onTouchStart={hundleChangeScene}  scene={"Top"} >
          <img src={backBtnImg} width={200} />
      </TopButton>

    </Root>
  );
}

const Root = styled.div`
  position: relative;
  height: 100vh; /* 全画面表示 */
`

const Title = styled.img`
  position: fixed;
  top: 150px;
  left: 50%;
  transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  width: 500px;
  text-align: center;
  color: #000;
  font-size: 2.5em;
  font-weight: bold;
`


const List = styled.ul`
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  width: 100%;
  margin-top: 10px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  grid-gap: 25px;
  text-align: center;
  > li {
    margin: auto;

  }
`

const ButtonStyle = styled.button`
  width: ${({ width }) => width ? width : "90%"};
  margin: 5px 5px;
  font-size: 2em;
  font-family: '小塚ゴシック Pro','Kozuka Gothic Pro','Hiragino Kaku Gothic ProN', sans-serif;
  background: ${({ theme }) => theme.color.key};
  padding: 10px 27px;
  border-radius: 40px;
  color: ${({ theme }) => theme.color.white};
  &:disabled {
    background: ${({ theme }) => theme.color.disable};
  }
`

      
const Decided= styled.div`
position: fixed;
bottom: 200px;
left: 50%;
transform: translateX(-50%);
-webkit-transform: translateX(-50%);
text-align: center;
`

const DecidedButton = styled.button`
  width: 300px;
  margin: 10px 5px;
  font-size: 2em;
  font-family: '小塚ゴシック Pro','Kozuka Gothic Pro','Hiragino Kaku Gothic ProN', sans-serif;
  background: #F4E128;
  padding: 10px 27px;
  border-radius: 40px;
  &:disabled {
    background: ${({ theme }) => theme.color.disable};
  }
`

const TopButton = styled(BackButton)`
position: absolute;
top: 40px;
left: 35px;
`